import React from "react";
import shopnotfound from "../assets/shop-not-found.svg";
import "./ShopNotFound.css";
import shopeglogo from "../assets/shopeg_logo.svg";

const ShopNotFound = () => {
  const handleClick = () => {
    window.location.href = "https://shopeg.in/";
  };

  const handleSeller = () => {
    window.location.href = "https://wa.me/message/XUJYONCJB4VKE1";
  };

  return (
    <div className="main-div">
      <div className="top-main">
        <div className="topleft">
          <img src={shopeglogo} alt="logo" />
          <p>ShopEG</p>
        </div>
      </div>
      <div className="not-found-div">
        <div className="not-found-div-image">
          <img src={shopnotfound} alt="Shop Not Found" />
        </div>
        <div className="button-div">
          <button className="action-button" onClick={handleClick}>
            Visit ShopEG.in
          </button>
          <button className="action-button" onClick={handleSeller}>
            Become A Seller
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShopNotFound;

import React from "react";
import { Link } from "react-router-dom";
import { firebaseAnalytics } from "../utils/firebase_config";
import { BrowserView, MobileView } from "react-device-detect";

const Menu = () => {
  firebaseAnalytics.logEvent("LANDING_PAGE_VIEW");
  return (
    <>
      <BrowserView>
        <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container">
            <a className="logo" style={{ marginLeft: "-9.7%" }} href="/">
              <div className="row">
                <img
                  className="img-fluid"
                  src="/i/eg.png"
                  style={{ height: "38px", width: "38px" }}
                  alt="ShopEG-Logo"
                />
                <h4
                  style={{
                    fontSize: "24px",
                    fontWeight: "800",
                    marginLeft: "8px",
                    color: "#232323",
                    marginBottom: "2px",
                  }}
                >
                  ShopEG
                </h4>
              </div>
            </a>

            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>

            <div id="navbar" className="collapse navbar-collapse">
              <ul className="nav navbar-nav navbar-right ml-auto flex-row">
                {/* Home Link Active */}
                <li>
                  <Link
                    className="link-nav-btn"
                    to="/"
                    style={{ fontSize: "16px", color: "#4CAF50" }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="link-nav-btn"
                    to="/AboutUs"
                    style={{ fontSize: "16px", color: "#898A8D" }}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <a
                    className="link-nav-btn"
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/message/XUJYONCJB4VKE1"
                    style={{ fontSize: "16px", color: "#898A8D" }}
                  >
                    Contact Us
                  </a>
                </li>
                <li> " "</li>
                <li className="download1" style={{ color: "232323" }}>
                  <a
                    href="https://d2ctrust.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    D2C Brand
                  </a>
                </li>

                <li>
                  <div className="btn-bg btn-group rounded btn-group-sm">
                    <Link
                      className="lang-btn btn-group-sm btn-primary active"
                      to="/"
                    >
                      English
                    </Link>
                    <Link
                      className="lang-btn btn-group-sm btn-primary"
                      to="/index-kan"
                    >
                      ಕನ್ನಡ
                    </Link>
                    <Link
                      className="lang-btn btn-group-sm btn-primary"
                      to="/index-hin"
                    >
                      हिन्दी
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </BrowserView>

      <MobileView>
        <div className="container">
          <a
            className="logo color-white"
            style={{ marginLeft: "-9.7%" }}
            href="/"
          >
            <div className="row">
              <img
                className="img-fluid"
                src="/i/eg.png"
                style={{ height: "38px", width: "38px" }}
                alt="ShopEG-Logo"
              />
              <h4
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                  marginLeft: "8px",
                  color: "#232323",
                  marginBottom: "2px",
                }}
              >
                ShopEG
              </h4>
            </div>
          </a>
          <div>
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="false"
              aria-controls="navbar"
            >
              <span className="sr-only"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
        </div>
      </MobileView>
    </>
  );
};

export default Menu;

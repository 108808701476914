import React from 'react';
import '../layout/featuresSection.css';

const FeatureCard = ({ image, title, description }) => {
  return (
    <div className="feature-card">
      <img src={image} alt={title} />
      <br/>
      <h3>{title}</h3>
      <br/>
      <p>{description}</p>
    </div>
  );
};

const FeaturesContainer = ({ features }) => {
  return (
    <div className="features-container">
      {features.map(feature => (
        <FeatureCard key={feature.id} {...feature} />
      ))}
    </div>
  );
};

const AppImage = ({ imageSrc, overlayColor }) => {
  return (
    <div className="app-image" style={{ backgroundImage: `url(${imageSrc})` }}>
      <div className="overlay" style={{ backgroundColor: overlayColor }}></div>
    </div>
  );
};

const FeaturesSection = ({ features, appImageSrc, overlayColor }) => {
  return (
    <section className="features-section">
      <AppImage imageSrc={appImageSrc} overlayColor={overlayColor} />
      <FeaturesContainer features={features} />
    </section>
  );
};

export default FeaturesSection;

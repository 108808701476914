import * as firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyAGIGN66i1CSQ4iZLeqEUMYdiPXFqJUoN8",
  authDomain: "shopez-cust.firebaseapp.com",
  databaseURL: "https://shopez-cust.firebaseio.com",
  projectId: "shopez-cust",
  storageBucket: "shopez-cust.appspot.com",
  messagingSenderId: "580689944441",
  appId: "1:580689944441:web:5cbbdcc6ccbd1e6bfc59a8",
  measurementId: "G-19HSS2K1LM"
};

firebase.initializeApp(firebaseConfig);



export let firebaseAnalytics = firebase.analytics();
export let  defaultAuth = firebase.auth();





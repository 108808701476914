// PricingCard.js
import React from 'react';
import './PricingCard.css';

const PricingCard = ({ title,subtitle, price, features, buttonLabel, isPrimary }) => {

  const handleJoinButtonClick = () => {
    window.open("https://api.whatsapp.com/send?phone=918618563552&text=Hi%2C%20I%20am%20interested%20in%20joining%20ShopEG", "_blank");
  };

  return (
    <div className={`card ${isPrimary ? 'card-primary' : ''}`}>
      <h3 className="title" style={{color: isPrimary? "#fff200":"#222"}}>{title}</h3>
      <h2 className="subtitle">{subtitle}</h2>
      <p className="price">{price}</p>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
     {isPrimary?  "": <button onClick={handleJoinButtonClick} >{buttonLabel}  </button>}
    </div>
  );
};

export default PricingCard;

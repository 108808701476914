import React from "react";
import Rive from "rive-react";
import styled from "styled-components";
import "../layout/hero.css"; 


const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 20px;
  margin-top: ${props => props.mobileMarginTop ? "0" : "70px"};
  @media (max-width: 738px) {
    margin-top: ${props => props.mobileMarginTop ? "0" : "40px"};
  }
`;

const Label = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #FFFFFF;
  color: #4CAF51;
  font-size: 22px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.1);
    
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: bold;
  }
`;

const HeroContent = styled.div`
  text-align: center;
  margin-top: 20px;
  
`;

const HeroText = styled.h1`
  font-size: 52px;
  font-weight: bold;
  margin-bottom: 20px;
  
  @media (max-width: 768px) {
    font-size: 40px;
    font-weight: bold;
  }
`;

const SubText = styled.p`
  font-size: 24px;
  color: #888;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Button = styled.button`
  background-color: #4CAF51;
  color: white;
  font-size: 24px;
  border-radius: 10px;
  padding: 20px 40px;
  box-shadow: 0px 4px 8px rgba(76, 175, 81, 0.2);
  cursor: pointer;
  border: none;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const RiveAnimation = styled(Rive)`
  justify-content: center;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
`;


const HeroSectionKan = () => {
  const handleJoinButtonClick = () => {
    window.open("https://api.whatsapp.com/send?phone=918618563552&text=%E0%B2%B9%E0%B2%BE%E0%B2%AF%E0%B3%8D%2C%20%E0%B2%A8%E0%B2%BE%E0%B2%A8%E0%B3%81%20ShopEG%20%E0%B2%B8%E0%B3%87%E0%B2%B0%E0%B2%B2%E0%B3%81%20%E0%B2%AC%E0%B2%AF%E0%B2%B8%E0%B3%81%E0%B2%A4%E0%B3%8D%E0%B2%A4%E0%B3%87%E0%B2%A8%E0%B3%86.", "_blank");
  };
  
  return (
    <HeroContainer >
      <Label>ಒ ಎನ್ ಡಿ ಸಿ ನಲ್ಲಿ ನಲ್ಲಿ ಮಾರಾಟ ಮಾಡಿ</Label>
      <HeroContent>
        <HeroText>
        ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಅಂಗಡಿಯ ಬಾಗಿಲು ತೆರೆಯಿರಿ
        </HeroText>
        <SubText>
        ಪ್ರತಿ ವಾರ ಕೇವಲ 15 ನಿಮಿಷಗಳನ್ನು ಕಳೆಯಿರಿ ಮತ್ತು ನಿಮ್ಮ ಸ್ಥಳೀಯ ಡುಕಾನ್‌ಗೆ ಆರ್ಡರ್‌ಗಳನ್ನು ಸ್ವೀಕರಿಸಿ
        </SubText>
        <Button onClick={handleJoinButtonClick}>ಶಾಪ್ ಈಜಿ ಸೇರಿ</Button>
        <RiveAnimation
          className="rive-anim"
          src="/assets/animation/scan_animation.riv"
          autoplay
          alt="Scanning_Shelf"
        />
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSectionKan;

import React, {useEffect, useState} from 'react';
import groceries from '../../../assets/groceries.svg'

const ProductsCard = ({product,shopData}) => {

    const [src,setSrc] = useState(`https://shopeg-product-images-v1.s3.ap-south-1.amazonaws.com/groceries/${product.productUuid}_0.jpg`)

    useEffect(()=> {

    });

    const onError = () => {
        console.log("on error", )
            setSrc(groceries)
    };

    return (
        <div className='productWrapper'>
            <div className='imageWrapper' onClick={()=> {window.location.href = shopData.link;}}>
                <img onError={onError} src={product.imgUrl ? product.imgUrl : src}/>
            </div>
            <div className='detailWrapper'>
                <h3>
                    {product.price && <span className='price'>₹{product.price || "--"} </span>}
                    {(!product.price && product.mrp) && <span className='price'>₹</span>}
                    {(product.mrp && (parseInt(product.mrp) !== parseInt(product.price))) && <span className='mrp' style={{textDecoration:(product.price && product.mrp) ? "line-through" : '',color:(product.price && product.mrp) ? '' : 'black',fontSize:(product.price && product.mrp) ? '' : '22px'}} >{product.mrp}</span>}
                </h3>
                <p className='productName'>{product.name || "--"}</p>
            </div>
        </div>
    );
};

export default ProductsCard;

import React from "react";
import Rive from "rive-react";
import styled from "styled-components";
import "../layout/hero.css"; 

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 20px;
  margin-top: ${props => props.mobileMarginTop ? "0" : "70px"};
  @media (max-width: 738px) {
    margin-top: ${props => props.mobileMarginTop ? "0" : "20px"};
  }
`;

const Label = styled.div`
  position: absolute;
  top: 60px;
  left: 20px;
  background-color: #FFFFFF;
  color: #4CAF51;
  font-size: 22px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.1);

  // Media query for mobile screens
  @media (max-width: 480px) {  // Adjust the max-width value based on your design requirements
    top: 10px;
  }
`;

const HeroContent = styled.div`
  text-align: center;
  margin-top: 20px;
  
`;

const HeroText = styled.h1`
  font-size: 52px;
  font-weight: bold;
  margin-bottom: 20px;
  
  @media (max-width: 768px) {
    font-size: 40px;
    font-weight: bold;
  }
`;

const SubText = styled.p`
  font-size: 24px;
  color: #888;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Button = styled.button`
  background-color: #4CAF51;
  color: white;
  font-size: 24px;
  border-radius: 10px;
  padding: 20px 40px;
  box-shadow: 0px 4px 8px rgba(76, 175, 81, 0.2);
  cursor: pointer;
  border: none;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const RiveAnimation = styled(Rive)`
  justify-content: center;
  align-items: start;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: -10px;
`;

const HeroSection = () => {
  const handleJoinButtonClick = () => {
    window.open("https://api.whatsapp.com/send?phone=918618563552&text=Hi%2C%20I%20am%20interested%20in%20joining%20ShopEG", "_blank");
  };

  return (
    <HeroContainer >
      <Label>Sell on ONDC</Label>
      <HeroContent>
        <HeroText>
        Open the Door to Your Online Store
        </HeroText>
        <SubText>
        Spend just 15 mins every week and receive orders to your local dukaan
        </SubText>
        <Button onClick={handleJoinButtonClick}>Join ShopEG</Button>
        <RiveAnimation
          className="rive-anim"
          src="/assets/animation/scan_animation.riv"
          autoplay
          alt="Scanning_Shelf"
          height= "300px"
        />
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;

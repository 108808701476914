// AboutUs.js

import React from "react";
import "./AboutUs.css"; // Ensure this CSS file is in the same folder as your component
import shopImage from "../assets/shop3.svg";
import Menu from "./MenuAboutUs";
import basanthImage from "../assets/images/team/basanth.png";
import ranjanaImage from "../assets/images/team/ranjana.png";
import { Link } from "react-router-dom";
import MenuAboutUsHin from "./MenuAboutUsHin";

const AboutUsHin = () => {
  const teamMembers = [
    {
      name: "बसंत",
      title: "सह संस्थापक",
      imageUrl: basanthImage,
      linkedin: "https://www.linkedin.com/in/basanthnverma/",
    },
    {
      name: "रंजना",
      title: "सह संस्थापक",
      imageUrl: ranjanaImage,
      linkedin: "https://www.linkedin.com/in/ranjana-verma/",
    },

    // { name: 'Vijai Chandra Prasad', title: 'Founding Engineer', imageUrl: 'https://m.media-amazon.com/images/I/71VosPIHljL._SX679_.jpg' },
  ];

  return (
    <div>
      <MenuAboutUsHin />
      <br />
      <br />
      <div className="about-container">
        <div className="our-story">
          <h1>हमारे बारे में</h1>
          <p>
            शॉप ईजी एक किराना एग्रीगेटर प्लेटफॉर्म है जिसका भारत भर में 6 मिलियन
            से अधिक छोटे किराना स्टोरों को डिजिटल बनाने का महत्वाकांक्षी लक्ष्य
            है। हमारा लक्ष्य इन स्टोर्स को ओपन नेटवर्क फॉर डिजिटल कॉमर्स
            (ओएनडीसी) में शामिल करना है, जो एक ऐसी पहल है जो भारत के खुदरा
            परिदृश्य को डिजिटल बनाने के हमारे मिशन के साथ पूरी तरह से मेल खाती
            है।
            <br />
            <br />
            हमारा अद्वितीय विक्रय प्रस्ताव हमारी एआई-आधारित कैटलॉग डिजिटलीकरण
            प्रणाली में निहित है। यह तकनीक हमें केवल 20-30 मिनट में 1000 से अधिक
            वस्तुओं की सूची के साथ एक स्टोर पर पहुंचने में सक्षम बनाती है, जिससे
            स्टोर मालिकों के लिए डिजिटल प्लेटफॉर्म पर संक्रमण के लिए आवश्यक समय
            और प्रयास काफी कम हो जाता है।
            <br />
            <br />
            हमारा मानना ​​है कि हमारा समाधान छोटे खुदरा स्टोरों के डिजिटलीकरण
            में तेजी लाकर ओएनडीसी पहल में महत्वपूर्ण योगदान दे सकता है, जो एक
            ऐसा क्षेत्र है जो भारत के खुदरा उद्योग की रीढ़ है। इन स्टोरों को
            ऑनलाइन लाकर, हम उन्हें व्यापक ग्राहक आधार तक पहुंचने, उनकी परिचालन
            दक्षता में सुधार करने और डिजिटल अर्थव्यवस्था में प्रभावी ढंग से
            प्रतिस्पर्धा करने में मदद कर सकते हैं।{" "}
          </p>
          <h1>हमारा विशेष कार्य</h1>
          <p>
            हम यहां भारत भर में छोटे किराना स्टोरों के लिए विकास और समृद्धि के
            अग्रणी प्रवर्तक बनने, डिजिटल परिवर्तन लाने और उन्हें उनके पड़ोस और
            उससे बाहर के ग्राहकों से जोड़ने के लिए आए हैं। हम भारत में छोटे
            किराना स्टोरों के संचालन और उनके ग्राहकों के साथ जुड़ने के तरीके को
            बदलने के मिशन पर हैं। हमारी यात्रा हमें देश भर में संपन्न, डिजिटल
            रूप से सक्षम किराना स्टोरों के लगातार बढ़ते नेटवर्क को बढ़ावा देने
            के साथ-साथ डिजिटल समाधानों के अपने सूट में लगातार नवाचार और विस्तार
            करते हुए दिखाई देगी। जैसे-जैसे हम आगे बढ़ते हैं, हमारा लक्ष्य
            स्थानीय स्टोर मालिकों के जीवन पर एक स्थायी, सकारात्मक प्रभाव पैदा
            करना है समुदाय, और समग्र रूप से भारतीय अर्थव्यवस्था।
          </p>
        </div>
        {/* <div className="our-story">
          <h1>Why We Are Here</h1>
          <p>
            We're here to become the leading enabler of growth and prosperity
            for small grocery stores across India, driving digital
            transformation and connecting them to customers in their
            neighbourhood and beyond.
          </p>
          <p></p>
        </div> */}
        <div className="connecting-kiranas">
          <img src={shopImage} alt="Kirana store owner" />
          <h1>
            किराना स्टोर की क्षमता का डिजिटलीकरण, व्यवस्थित और विस्तार करना
          </h1>
        </div>
        <div className="team-section">
          <h1>हमारी टीम से मिलें</h1>
          <div className="team-members">
            {teamMembers.map((member) => (
              <div className="team-member" key={member.name}>
                <img src={member.imageUrl} alt={member.name} />
                <h3>{member.name}</h3>
                <p>{member.title}</p>
                <a
                  href={member.linkedin}
                  className="link color-black"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="LinkedIn"
                >
                  <i
                    class="fab fa-linkedin-in"
                    style={{ fontSize: "24px", width: "20px", height: "20px" }}
                  ></i>
                </a>
                {/* <i className="team-icon"></i> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <footer
        className="pt-70 pb-20  text-center footer_1 "
        style={{
          background: "linear-gradient(120deg, #4527A0,#8862F1, #4527A0 )",
        }}
      >
        <div className="container px-xl-0">
          <div className="row justify-content-between align-items-center lh-40 links">
            <div
              className="col-lg-6 col-sm-6 text-sm-right text-lg-left order-1 order-lg-0"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <a
                href="https://wa.me/message/XUJYONCJB4VKE1"
                alt="Contact Sheet"
                className="link mx-15 color-white"
                target="_blank"
                rel="noreferrer"
              >
                संपर्क करें
              </a>
              <a href="/privacy.html" className="link mx-15 color-white">
                गोपनीयता नीति
              </a>
              <a
                href="/seller-agreement.html"
                className="link mx-15 color-white"
              >
                शर्तें और समझौता
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 text-sm-left text-lg-right order-2 order-lg-0"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <a
                href="https://wa.me/message/XUJYONCJB4VKE1"
                className="link mr-15 color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="WhatsApp"
              >
                <i className="fab fa-whatsapp"></i>
              </a>
              <a
                href="https://twitter.com/ShopEG_IN"
                className="mx-15 link color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.facebook.com/ShopEGapp/"
                className="mx-15 link color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
              <a
                href="https://www.instagram.com/shopeg.in/"
                className="ml-15 link color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram "></i>
              </a>
            </div>
          </div>
          <div
            className="mb-10 mb-lg-0 col-lg-auto order-0"
            data-aos="fade-down"
            data-aos-delay="0"
          >
            <a className="link logo color-main" href="/">
              <div className="row">
                {" "}
                <img src="/i/eg.png" height="32" width="30" alt="ShopEG logo" />
                <p className="color-white" style={{ marginLeft: "4px" }}>
                  {" "}
                  शॉप ईजी
                </p>
              </div>
            </a>
          </div>
          <div className="row justify-content-center color-white">
            <div
              className="mt-10 col-xl-4 col-lg-5 col-md-6 col-sm-8"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              <div className="color-white text-adaptive">
                © कॉपीराइ स्पार्क इटरनल टेक्नोलॉजीस प्राइवेट लिमिटेड। सभी अधिकार
                सुरक्षित
                <span>
                  <p></p>
                  <p>
                    {" "}
                    No. 65, 83rd Cross, 1st Stage Kumaraswamy Layout, Bangalore,
                    Karnataka, 560078
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutUsHin;

import React, {useEffect, useState} from 'react';
import offer from '../../../assets/offers/bsv.png'
import ImgsViewer from "react-images-viewer";

const Offers = (props) => {


    useEffect(() => {

    });



    useEffect(()=> {
        console.log("adding interval")
        let interval = setInterval(()=> {
            currentImage ++;
            if(currentImage >2){
                currentImage = 1
            }
            else if(currentImage < 0){
                currentImage = 0
            }
            setCurrentImage(currentImage)
        }, 4000);
        return () => {
            console.log("clearing interval");
            clearInterval(interval)}
    },[]);


    let [offerImages,setOfferImages] = useState(props.shopData.offerImages);
    let [currentImage,setCurrentImage] = useState(0);

    return (
        <div className='offersWrapper '>
            <h2 className='heading'>Offers</h2>
            <div className='carousel'>
                {/*{offerImages.map((image,index) => {*/}
                {/*    return <img onError={() => {*/}
                {/*        // let imageStat = imageStatus;*/}
                {/*        // imageStatus[index] = false;*/}
                {/*        // setImageStatus(imageStat);*/}
                {/*    }}*/}
                {/*    src={image}*/}
                {/*    alt={'offer_image'}/>*/}
                {/*})}*/}

                <img onError={() => {
                    offerImages.splice(currentImage, 1)
                }}
                     src={offerImages[currentImage]}
                     alt={'offer_image'}/>
            </div>
        </div>
    );
};


const OffersCard = () => {
    return (
        <div>

        </div>
    );
};

export default Offers;

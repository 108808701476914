import React, { useState } from "react";
import "../layout/PricingSection.css";
import styled from "styled-components";
import boriah from "../assets/images/reviews/boriah.png";
import sohanlal from "../assets/images/reviews/sohanlal.png";
import bsvThumbnail from "../assets/images/reviews/bsv-thumbnail.png";
import dpsThumbnail from "../assets/images/reviews/dps-thumbnail.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ReviewSectionWrapper = styled.section`
  padding: 20px 0;

  text-align: center;
  overflow: hidden; // To ensure no horizontal overflow
`;

const Heading = styled.h2`
  color: #333;
  margin-bottom: 60px;
`;

const ReviewContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 20px;
  justify-content: center; // Always align to start
  padding-left: 40px; // Consistent padding on the left

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-left: 0; // Reset padding for mobile view
  }
`;

const ReviewPoints = styled.li`
  padding: none;
  color: #666;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReviewCard = styled.div`
  flex: 0 0 auto;
  width: calc(30% - 20px); // Adjusted width to include margin
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 20px; // Added margin for separation between cards
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;

  @media (max-width: 768px) {
    width: calc(100% - 40px); // Adjusted width to include margin for mobile view
    margin-right: 0; // No margin for mobile view
  }
`;


const ReviewText = styled.p`
  font-size: 16px;
  color: #333;
  fontstyle: "italic";
`;

const ReviewTextAnswer = styled.p`
  font-size: 16px;
  color: #666;
  fontstyle: "italic";
`;

const ShopLink = styled.a`
  font-size: 16px;
  color: #4caf50 !important;
  margin-bottom: 10px;
`;

const ReviewerInfo = styled.div`
  font-size: 16px;
  color: #333;
  margin-top: 10px;
`;

const ReviewImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
`;

const Rating = styled.div`
  color: #ffc107; // Color for the star ratings
  margin-bottom: 5px;
`;

const PlayButton = styled.button`
  position: relative;
  top: 40%;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s ease; /* Add transition for a smooth effect */

  &:hover {
    box-shadow: 0 6px 8px rgba(2, 2, 2, 0.6); /* Add drop shadow on hover */
  }

  &:after {
    content: "▶"; // Unicode for play symbol
  }
`;

const VideoModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;

const VideoModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ReviewSection = () => {
  const reviews = [
    {
      text1: "How is ShopEG useful to you?",
      text1answer: "Customers today browse products online whether they intend to buy or not. Since our catalog is available online, they’re able to stay connected with us conveniently. It’s like advertising our store. They are able to place orders without my assistance because I’m busy making deliveries or attend customers at the store.",
      // text2: "How is ShopEG useful to your customers?",
      // text2answer: "For Customers it is easy to order online, on phone call there are a lot of disturbance and i can't write the order correctly,  I'll be busy or out for other deliveries. With ShopEG they can select all the items in our shop and order, later i can accept and deliver the order.",
      text1answerkan: "ಜನಕ್ಕೆ ಚನಾಗ್ ಗೊತ್ತಾಗಿದೆ, ಆನ್ಲೈನ್ ​​ಇರೋದ್ರಿಂದ ಬೇಗ ಕನೆಕ್ಟ್ ಆಗ್ತಾರೆ, ಫೋನ್ ಕೋಲ್ ಮಾಡುವುದಕ್ಕಿಂತ ಶಾಪ್ ಈಜಿ ಅಲ್ಲೆ ಆರ್ಡರ್ ಮಾಡೋಕೆ ತುಂಬಾ ಫಾಸ್ಟ್ ಮತ್ತು ಸುಲಭವಾಗಿದೆ",
      // text2kan: "ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ಶಾಪ್ ಈಜಿ ಹೇಗೆ ಉಪಯುಕ್ತವಾಗಿದೆ?",
      // text2answerkan: "ಕಸ್ಟಮರ್ಸ್ ಗೆ ಯೆಲ್ಲಾ ಆನ್ ಲೈನ್ ಆರ್ಡರ್ ಮಾಡೋಕೆ ಅನುಕೂಲ ಆಗಿದೆ, ಫೋನ್ ಅಲ್ಲಿ ಆರ್ಡರ್ ಮಾಡ್ತಾರೆ ಡಿಸ್ಟರ್ಬ್ನ್ಸ್ ಇರುತ್ತೆ ನಾನು ಸರಿಯಾಗೆ ಬರ್ಕೊಳಕ್ಕೆ ಆಗಲ್ಲ, ನಾನೂ ಬ್ಯುಸಿ ಇರ್ತೀನಿ ಡೆಲಿವರಿ ಗೆ ಹೋಗ್ತೀನಿ. ಶಾಪ್ ಈಜಿ ಅಲ್ಲಿ ಆದ್ರೆ ನಮ್ ಅಂಗಡಿ ಅಲ್ಲಿ ಇರ ಐಟಂ ಸೆಲೆಕ್ಟ್ ಮಾಡ್ಬೇಕು ಮತ್ತೆ ಮತ್ತೆ ಆರ್ಡರ್ ಮಾಡ್ಬೋದು, ಆಮೇಲೇ ನಾನೂ ಅದನ್ನೂ ನೋಡಿ ಆರ್ಡರ್ ಸ್ವೀಕಾರ ಮಾಡಿ ಡೆಲಿವರಿ ಮಾಡ್ಬೋದು",
      name: "Mr. Boriah",
      position: "Shopkeeper",
      image: boriah, // Replace with the path to your images
      rating: 5,
      id: "0dNe-5LxaJc?si=_Yu0SQJ8yTRLGQ3A",
      shopUrl: "https://shopeg.in/basaveshwara-rice-corner",
      shopName: "Basaveshwara Rice Corner",
      videoThumbnailUrl: bsvThumbnail,
    },
    {
      text1: "How is ShopEG useful to you?",
      text1answer: "We have created our online catalog by entering the product prices using ShopEG. We are also providing discounts. Customers are selecting products and placing their order. Our delivery person is able to make the deliveries in the ISRO layout area.",
      // text2: "How is ShopEG useful to your customers?",
      // text2answer: "It is easy for the customers to order from ShopEG. "
      text1answerkan: "ಶಾಪ್ ಈಜಿ ಇಂದಾ ಆಗಲೇ ಆರ್ಡರ್ ಬರ್ತಿದೆ, ಅದ್ರಲ್ಲಿ ಬೆಲೆ ಹಾಕಿದೀವಿ ಡಿಸ್ಕೌಂಟ್ ರೇಟ್ ಯೆಲ್ಲಾ ಇದೆ, ಆರ್ಡರ್ ಬರ್ತಿದೆ ನಾವು ಡೆಲಿವರಿ ಮಾಡ್ತಿದೀವಿ. ಡೆಲಿವರಿ ಬಾಯ್ ಆರಾಮಾಗಿ ಆರ್ಡರ್ ತೊಗೊಂಡ್ ಹೋಗ್ತಾರೆ.",
      // text2kan: "ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ಶಾಪ್ ಈಜಿ ಹೇಗೆ ಉಪಯುಕ್ತವಾಗಿದೆ?",
      // text2answerkan: "ಕಗ್ರಾಹಕರಿಗೆ ಆರ್ಡರ್ ಮಾಡೋಕೆ ಸುಲಭ ಆಗಿದೆ ಶಾಪ್ ಈಜಿ ಅಲ್ಲಿ",
      name: "Mr. Sohanlal",
      position: "Shopkeeper",
      image: sohanlal, // Replace with the path to your images
      rating: 3,
      id: "AGKf32pa4vM",
      shopUrl: "https://shopeg.in/deepak-provision-store",
      shopName: "Deepak Provision Store",
      videoThumbnailUrl: dpsThumbnail,
    },

    // Add more placeholder reviews as needed
    // ...
  ];

  const VideoModal = ({ url, handleClose }) => {
    // Ensure URL is correct for YouTube embed
    const youtubeEmbedUrl = `https://www.youtube.com/embed/${url}?autoplay=1`;

    return (
      <VideoModalBackdrop onClick={handleClose}>
        <VideoModalContent onClick={(e) => e.stopPropagation()}>
          <VideoModalCloseButton onClick={handleClose}>
            &times;
          </VideoModalCloseButton>
          <iframe
            src={youtubeEmbedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video player"
            width="660"
            height="415"
          ></iframe>
        </VideoModalContent>
      </VideoModalBackdrop>
    );
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [videoId, setVideoId] = useState("");

  const handlePlayVideo = (videoId) => {
    setVideoId(videoId);
    setModalOpen(true);
  };

  return (
    <ReviewSectionWrapper>
      <Heading>
      ನಮ್ಮ ಅಂಗಡಿಯವರು ಏನು ಹೇಳುತ್ತಿದ್ದಾರೆ
      </Heading>
      <ReviewContainer>
        {isModalOpen && (
          <VideoModal url={videoId} handleClose={() => setModalOpen(false)} />
        )}
        {reviews.map((review, index) => (
          <ReviewCard key={index}>
            <div
              style={{
                position: "relative",
                width: "280px",
                height: "180px",
                margin: "0 auto",
              }}
            >
              <img
                src={review.videoThumbnailUrl} // replace 'videoThumbnailUrl' with the actual variable or prop holding the thumbnail URL
                alt="Video Thumbnail"
                width={280}
                height={180}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: "auto",
                  transition: "box-shadow 0.3s ease", // Add transition for a smooth effect
                  cursor: "pointer",
                }}
                onClick={() => handlePlayVideo(review.id)}
                className="thumbnail-image" // Optionally, you can add a class for styling
              />
              <PlayButton onClick={() => handlePlayVideo(review.id)} />
            </div>
            <br />
            <ShopLink>
              <a href={review.shopUrl} target="_blank" rel="noreferrer">
                {review.shopName}
              </a>
            </ShopLink>
            <ReviewerInfo>
              {review.name}
            </ReviewerInfo>
            {/* <ReviewImage src={review.image} alt={review.name} /> */}
            {/* <Rating>
              {'★'.repeat(review.rating)}
              {'☆'.repeat(5 - review.rating)}
            </Rating> */}
            <br />
            {/* <ReviewText>
              {review.text1}
            </ReviewText> */}
            <ReviewTextAnswer>
              <b style={{ fontSize: "24px" }}>“</b>
             <i> {review.text1answerkan}</i>
            </ReviewTextAnswer>
            {/* <ReviewPoints>{review.point1}</ReviewPoints>
            <ReviewPoints>{review.point2}</ReviewPoints>
            <ReviewPoints>{review.point3}</ReviewPoints>
            <ReviewPoints>{review.point4}</ReviewPoints> */}
            {/* <ReviewText>{review.textkannada}</ReviewText>
            <ReviewPoints>{review.point1kan}</ReviewPoints>
            <ReviewPoints>{review.point2kan}</ReviewPoints>
            <ReviewPoints>{review.point3kan}</ReviewPoints>
            <ReviewPoints>{review.point4kan}</ReviewPoints> */}
               <br />
               <ReviewTextAnswer>
              <b style={{ fontSize: "24px" }}>“</b>
             <i> {review.text1answer}</i>
            </ReviewTextAnswer>
          </ReviewCard>
        ))}
      </ReviewContainer>
    </ReviewSectionWrapper>
  );
};

export default ReviewSection;

import React, { useState } from "react";
import "../layout/PricingSection.css";
import styled from "styled-components";
import boriah from "../assets/images/reviews/boriah.png";
import sohanlal from "../assets/images/reviews/sohanlal.png";
import bsvThumbnail from "../assets/images/reviews/bsv-thumbnail.png";
import dpsThumbnail from "../assets/images/reviews/dps-thumbnail.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ReviewSectionWrapper = styled.section`
  padding: 20px 0;

  text-align: center;
  overflow: hidden; // To ensure no horizontal overflow
`;

const Heading = styled.h2`
  color: #333;
  margin-bottom: 60px !important;
`;

const ReviewContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 20px;
  justify-content: start; // Always align to start
  padding-left: 40px; // Consistent padding on the left

  &::-webkit-scrollbar {
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-left: 0; // Reset padding for mobile view
  }
`;

const ReviewPoints = styled.li`
padding: none;
color: #666;
text-align: left;
display: flex;
justify-content: center;
align-items: center;

`;

const ReviewCard = styled.div`
  flex: 0 0 auto;
  width: 380px; // Fixed width for cards
  padding: 20px;
  margin-bottom: 20px; // Added margin for separation between cards
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;

  @media (max-width: 768px) {
    width: calc(100vw - 60px); // Full width for mobile view
  }
`;

const ReviewText = styled.p`
  font-size: 16px;
  color: #666;
  fontStyle: 'italic'
`;

const ShopLink = styled.a`
  font-size: 16px;
  color: #4caf50 !important;
  margin-bottom: 10px;
`;

const ReviewerInfo = styled.div`
  font-size: 16px;
  color: #333;
  margin-top: 10px;
`;

const ReviewImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
`;

const Rating = styled.div`
  color: #ffc107; // Color for the star ratings
  margin-bottom: 5px;
`;

const PlayButton = styled.button`
  position: relative;
  top: 40%;
  bottom: 20px; 
  left: 50%;
  transform: translateX(-50%); 
  width: 30px; 
  height: 40px; 
  border: none;
  border-radius: 50%;
  background-color: #4caf50; 
  color: white;
  font-size: 16px; 
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "▶"; // Unicode for play symbol
  }
`;

const VideoModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;

const VideoModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ReviewSection = () => {
  const reviews = [
    {
      text: "ShopEG app has helped:",
      point1: "In customer awareness",
      point2: "Our customers to order easily",
      point3: "In easy order management",
      point4: "Get more sales",
      textkannada: "ಶಾಪ್ ಈಜಿ ಅಪ್ಲಿಕೇಶನ್ ನಮ್ಮನ್ನು ಸಹಾಯಕವಾಗಿದೆ:",
      point1kan: "ಜನಕ್ಕೆ ಗೊತ್ತಾಗಿದೆ",
      point2kan: "ಸುಲಭವಾಗಿ ಆರ್ಡರ್ ನೀಡಲು",
      point3kan: "ಆರ್ಡರ್‌ಗಳನ್ನು ಸುಲಭವಾಗಿ ನಿರ್ವಹಿಸಲು",
      point4kan: "ಹೆಚ್ಚಿನ ಮಾರಾಟ",
      name: "Mr. Boriah",
      position: "Shopkeeper",
      image: boriah, // Replace with the path to your images
      rating: 5,
      id: "0dNe-5LxaJc?si=_Yu0SQJ8yTRLGQ3A",
      shopUrl: "https://shopeg.in/basaveshwara-rice-corner",
      shopName: "Basaveshwara Rice Corner",
      videoThumbnailUrl: bsvThumbnail,
    },
    {
      text: "ShopEG app has helped us in:",
      point1: "Receiving online orders",
      point2: "Easy to reach customers",
      point3: " Delivering orders easily",
      point4: "Easy to order",
      textkannada: "ಶಾಪ್ ಈಜಿ ಅಪ್ಲಿಕೇಶನ್ ನಮ್ಮನ್ನು ಸಹಾಯಕವಾಗಿದೆ:",
      point1kan: "ಆನ್‌ಲೈನ್ ಆರ್ಡರ್‌ಗಳ ಸ್ವೀಕೃತಿ",
      point2kan: "ಗ್ರಾಹಕರನ್ನು ಸುಲಭವಾಗಿ ಸೇರಿಸುವುದು",
      point3kan: "ಆರ್ಡರ್‌ಗಳನ್ನು ಸುಲಭವಾಗಿ ವಹಿಸುವುದು",
      point4kan: "ಸುಲಭವಾಗಿ ಆರ್ಡರ್ ನೀಡುವುದು ",
      name: "Mr. Sohanlal",
      position: "Shopkeeper",
      image: sohanlal, // Replace with the path to your images
      rating: 3,
      id: "AGKf32pa4vM",
      shopUrl: "https://shopeg.in/deepak-provision-store",
      shopName: "Deepak Provision Store",
      videoThumbnailUrl: dpsThumbnail,
    },
    {
      text: "ShopEG app has helped us in:",
      point1: "Receiving online orders",
      point2: "Easy to reach customers",
      point3: " Delivering orders easily",
      point4: "Easy to order",
      textkannada: "ಶಾಪ್ ಈಜಿ ಅಪ್ಲಿಕೇಶನ್ ನಮ್ಮನ್ನು ಸಹಾಯಕವಾಗಿದೆ:",
      point1kan: "ಆನ್‌ಲೈನ್ ಆರ್ಡರ್‌ಗಳ ಸ್ವೀಕೃತಿ",
      point2kan: "ಗ್ರಾಹಕರನ್ನು ಸುಲಭವಾಗಿ ಸೇರಿಸುವುದು",
      point3kan: "ಆರ್ಡರ್‌ಗಳನ್ನು ಸುಲಭವಾಗಿ ವಹಿಸುವುದು",
      point4kan: "ಸುಲಭವಾಗಿ ಆರ್ಡರ್ ನೀಡುವುದು ",
      name: "Mr. Sohanlal",
      position: "Shopkeeper",
      image: sohanlal, // Replace with the path to your images
      rating: 3,
      id: "AGKf32pa4vM",
      shopUrl: "https://shopeg.in/deepak-provision-store",
      shopName: "Deepak Provision Store",
      videoThumbnailUrl: dpsThumbnail,
    },
    {
      text: "ShopEG app has helped us in:",
      point1: "Receiving online orders",
      point2: "Easy to reach customers",
      point3: " Delivering orders easily",
      point4: "Easy to order",
      textkannada: "ಶಾಪ್ ಈಜಿ ಅಪ್ಲಿಕೇಶನ್ ನಮ್ಮನ್ನು ಸಹಾಯಕವಾಗಿದೆ:",
      point1kan: "ಆನ್‌ಲೈನ್ ಆರ್ಡರ್‌ಗಳ ಸ್ವೀಕೃತಿ",
      point2kan: "ಗ್ರಾಹಕರನ್ನು ಸುಲಭವಾಗಿ ಸೇರಿಸುವುದು",
      point3kan: "ಆರ್ಡರ್‌ಗಳನ್ನು ಸುಲಭವಾಗಿ ವಹಿಸುವುದು",
      point4kan: "ಸುಲಭವಾಗಿ ಆರ್ಡರ್ ನೀಡುವುದು ",
      name: "Mr. Sohanlal",
      position: "Shopkeeper",
      image: sohanlal, // Replace with the path to your images
      rating: 3,
      id: "AGKf32pa4vM",
      shopUrl: "https://shopeg.in/deepak-provision-store",
      shopName: "Deepak Provision Store",
      videoThumbnailUrl: dpsThumbnail,
    },
    {
      text: "ShopEG app has helped us in:",
      point1: "Receiving online orders",
      point2: "Easy to reach customers",
      point3: " Delivering orders easily",
      point4: "Easy to order",
      textkannada: "ಶಾಪ್ ಈಜಿ ಅಪ್ಲಿಕೇಶನ್ ನಮ್ಮನ್ನು ಸಹಾಯಕವಾಗಿದೆ:",
      point1kan: "ಆನ್‌ಲೈನ್ ಆರ್ಡರ್‌ಗಳ ಸ್ವೀಕೃತಿ",
      point2kan: "ಗ್ರಾಹಕರನ್ನು ಸುಲಭವಾಗಿ ಸೇರಿಸುವುದು",
      point3kan: "ಆರ್ಡರ್‌ಗಳನ್ನು ಸುಲಭವಾಗಿ ವಹಿಸುವುದು",
      point4kan: "ಸುಲಭವಾಗಿ ಆರ್ಡರ್ ನೀಡುವುದು ",
      name: "Mr. Sohanlal",
      position: "Shopkeeper",
      image: sohanlal, // Replace with the path to your images
      rating: 3,
      id: "AGKf32pa4vM",
      shopUrl: "https://shopeg.in/deepak-provision-store",
      shopName: "Deepak Provision Store",
      videoThumbnailUrl: dpsThumbnail,
    },
    {
      text: "ShopEG app has helped us in:",
      point1: "Receiving online orders",
      point2: "Easy to reach customers",
      point3: " Delivering orders easily",
      point4: "Easy to order",
      textkannada: "ಶಾಪ್ ಈಜಿ ಅಪ್ಲಿಕೇಶನ್ ನಮ್ಮನ್ನು ಸಹಾಯಕವಾಗಿದೆ:",
      point1kan: "ಆನ್‌ಲೈನ್ ಆರ್ಡರ್‌ಗಳ ಸ್ವೀಕೃತಿ",
      point2kan: "ಗ್ರಾಹಕರನ್ನು ಸುಲಭವಾಗಿ ಸೇರಿಸುವುದು",
      point3kan: "ಆರ್ಡರ್‌ಗಳನ್ನು ಸುಲಭವಾಗಿ ವಹಿಸುವುದು",
      point4kan: "ಸುಲಭವಾಗಿ ಆರ್ಡರ್ ನೀಡುವುದು ",
      name: "Mr. Sohanlal",
      position: "Shopkeeper",
      image: sohanlal, // Replace with the path to your images
      rating: 3,
      id: "AGKf32pa4vM",
      shopUrl: "https://shopeg.in/deepak-provision-store",
      shopName: "Deepak Provision Store",
      videoThumbnailUrl: dpsThumbnail,
    },
    {
      text: "ShopEG app has helped us in:",
      point1: "Receiving online orders",
      point2: "Easy to reach customers",
      point3: " Delivering orders easily",
      point4: "Easy to order",
      textkannada: "ಶಾಪ್ ಈಜಿ ಅಪ್ಲಿಕೇಶನ್ ನಮ್ಮನ್ನು ಸಹಾಯಕವಾಗಿದೆ:",
      point1kan: "ಆನ್‌ಲೈನ್ ಆರ್ಡರ್‌ಗಳ ಸ್ವೀಕೃತಿ",
      point2kan: "ಗ್ರಾಹಕರನ್ನು ಸುಲಭವಾಗಿ ಸೇರಿಸುವುದು",
      point3kan: "ಆರ್ಡರ್‌ಗಳನ್ನು ಸುಲಭವಾಗಿ ವಹಿಸುವುದು",
      point4kan: "ಸುಲಭವಾಗಿ ಆರ್ಡರ್ ನೀಡುವುದು ",
      name: "Mr. Sohanlal",
      position: "Shopkeeper",
      image: sohanlal, // Replace with the path to your images
      rating: 3,
      id: "AGKf32pa4vM",
      shopUrl: "https://shopeg.in/deepak-provision-store",
      shopName: "Deepak Provision Store",
      videoThumbnailUrl: dpsThumbnail,
    },

    // Add more placeholder reviews as needed
    // ...
  ];

  const VideoModal = ({ url, handleClose }) => {
    // Ensure URL is correct for YouTube embed
    const youtubeEmbedUrl = `https://www.youtube.com/embed/${url}?autoplay=1`;

    return (
      <VideoModalBackdrop onClick={handleClose}>
        <VideoModalContent onClick={(e) => e.stopPropagation()}>
          <VideoModalCloseButton onClick={handleClose}>
            &times;
          </VideoModalCloseButton>
          <iframe
            src={youtubeEmbedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video player"
            width="560"
            height="315"
          ></iframe>
        </VideoModalContent>
      </VideoModalBackdrop>
    );
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [videoId, setVideoId] = useState("");

  const handlePlayVideo = (videoId) => {
    setVideoId(videoId);
    setModalOpen(true);
  };

  return (
    <ReviewSectionWrapper>
      <Heading>Our Clients Feedback</Heading>
      <ReviewContainer>
        {isModalOpen && (
          <VideoModal url={videoId} handleClose={() => setModalOpen(false)} />
        )}
        {reviews.map((review, index) => (
          <ReviewCard key={index}>
            <div
              style={{
                position: "relative",
                width: "240px",
                height: "160px",
                margin: "0 auto",
                }}>
              <img
                src={review.videoThumbnailUrl} // replace 'videoThumbnailUrl' with the actual variable or prop holding the thumbnail URL
                alt="Video Thumbnail"
                width={240}
                height={160}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: "auto",
                 }}
                onClick={() => handlePlayVideo(review.id)}
              />
              <PlayButton onClick={() => handlePlayVideo(review.id)} />
            </div>
            {/* <ReviewImage src={review.image} alt={review.name} /> */}
            {/* <Rating>
              {'★'.repeat(review.rating)}
              {'☆'.repeat(5 - review.rating)}
            </Rating> */}
            <br />
            <ReviewText><b style={{fontSize: "24px", }}>“</b>{review.text}</ReviewText>
            {/* <ReviewPoints>{review.point1}</ReviewPoints>
            <ReviewPoints>{review.point2}</ReviewPoints>
            <ReviewPoints>{review.point3}</ReviewPoints>
            <ReviewPoints>{review.point4}</ReviewPoints> */}
            <br />
            <ReviewText><b style={{fontSize: "24px", }}>“</b>{review.textkannada}</ReviewText>
            {/* <ReviewPoints>{review.point1kan}</ReviewPoints>
            <ReviewPoints>{review.point2kan}</ReviewPoints>
            <ReviewPoints>{review.point3kan}</ReviewPoints>
            <ReviewPoints>{review.point4kan}</ReviewPoints> */}
            <br />
            <ReviewerInfo>
              {review.name}
              <br />
              {review.position}
            </ReviewerInfo>
            <ShopLink>
              <a href={review.shopUrl} target="_blank" rel="noreferrer">
                {review.shopName}
              </a>
            </ShopLink>
            <br />
            <br />
          </ReviewCard>
        ))}
      </ReviewContainer>
    </ReviewSectionWrapper>
  );
};

export default ReviewSection;

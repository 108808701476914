import React from "react";
// import AOS from "aos";
import "aos/dist/aos.css";
import { Link, Redirect } from "react-router-dom";
import Rive from "rive-react";
import Menu from "./Menu";

// import { TwitterTimelineEmbed } from "react-twitter-embed";

import HeroSection from "../layout/hero";
import styled from "styled-components";
import FeaturesSection from "../layout/featuresSection";
import PricingSection from "../layout/PricingSection";
import scanIcon from "../assets/icons/scan.svg";
import bagIcon from "../assets/icons/bag.svg";
import increaseIcon from "../assets/icons/money-increase.svg";
import commissionIcon from "../assets/icons/low-commission.svg";
import HowTo from "../layout/HowTo";
import ReviewSection from "../layout/reviewSection";
import ReviewSection2 from "../layout/reviewSection2";
import InstagramEmbed from "react-instagram-embed";
import LogoSection from "../layout/logoContainer";

const StyledCol = styled.div`
  display: flex;
  justify-content: center;
  height: 350px;
  width: 320px;
  @media (max-width: 768px) {
    height: 300px;
    width: 90%;
  }
`;

const StyledImage = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 10px;
 
  width: 320px;
`;

const labelStyle = {
  position: "absolute",
  top: "20px",
  left: "20px",
  backgroundColor: "#FFFFFF", // Label background color
  color: "#4CAF51", // Text color
  fontSize: "22px", // Adjusted font size
  fontWeight: 600, // Adjusted font weight
  padding: "12px 20px",
  borderRadius: "10px",
  boxShadow: "0px 4px 8px rgba(32, 32, 32, 0.1)",
};

const homePage = ``;

class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    //   return (
    //     <div className="content" dangerouslySetInnerHTML={{__html: homePage}}></div>
    // );
    // return (
    //   <Redirect to="i" />
    // )

    const featuresData = [
      {
        id: 1,
        title: "Scan Products Using Camera",
        description:
          "Scan products at your store using our camera based AI and create online catalog instantly.",
        image: scanIcon,
      },
      {
        id: 2,
        title: "Get Orders",
        description:
          "Receive orders without any marketing efforts. Manage orders with ease on ShopEG.",
        image: bagIcon,
      },
      {
        id: 3,
        title: "Low Commission",
        description:
          "Maximum earning on every order with minimal fees/charges on ShopEG.",
        image: commissionIcon,
      },
      {
        id: 4,
        title: "More Sales",
        description:
          "Unlock increased sales potential with ShopEG through ONDC, serving customers across various pincodes.",
        image: increaseIcon,
      },
    ];

    return (
      <div className="home">
        <div>
          <Menu style={{ background: "#F7F7F7" }} />
          <br />
          <header className="pt-40 pb-60 header_1 ">
            <div className="container hero ">
              <div
                className="row align-items-center "
                data-aos="fade-down"
                data-aos-delay="10"
              >
                <HeroSection
                  className="container"
                  data-aos="fade-down"
                  data-aos-delay="10"
                />

                {/* <div
                  className="col-md-6 "
                  data-aos="fade-down"
                  data-aos-delay="10"
                >
                  <h1
                    className="mt-60 f-42 text-left font-weight-bold"
                    data-aos="fade-down"
                    data-aos-delay="10"
                  >
                    Take your Shop online using ShopEG AI within seconds.
                  </h1>

                  <div
                    className="mw-600 mx-auto mt-30 f-22 color-heading text-left text-adaptive"
                    data-aos="fade-down"
                    data-aos-delay="250"
                  >
                    Create catalog, Receive orders, Increase sales
                  </div>
                  <div
                    className="mw-600 mx-auto mt-30 f-16 color-black text-left text-adaptive"
                    data-aos="fade-down"
                    data-aos-delay="250"
                  >
                    Trusted and used by 100s of shopkeepers and their customers
                    across Bengaluru!
                  </div>

                  <br />
                  <br />

                  <div
                    className="page-content page-container"
                    id="page-content"
                    data-aos="fade-down"
                    data-aos-delay="0"
                  >
                    <div className="padding">
                      <div className="row container d-md-flex justify-left ">
                        <div className=" mt-2">
                          <a
                            rel="ShopEG Playstore"
                            href="https://play.google.com/store/apps/details?id=com.beyondseek.shopez.cust&referrer=utm_source%3Dshopeg.in"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/i/playstore.jpg"
                              width="200px"
                              height="60px"
                              style={{
                                "margin-top": "10px",
                                "margin-right": "10px",
                              }}
                              alt="playstore"
                            />
                          </a>
                          <a
                            rel="ShopEG Appstore"
                            href="https://apps.apple.com/in/app/shopeg/id1505233236"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/i/appstore.jpg"
                              width="200px"
                              height="60px"
                              style={{ "margin-top": "10px" }}
                              alt="Appstore"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              

                <div
                  className="col-md-6"
                  data-aos="fade-down "
                  data-aos-delay="100"
                >
                  <div
                    className="center"
                    style={{ "margin-right": "20% !important" }}
                  >
                    <Rive
                      className="rive-anim"
                      src="/assets/animation/scan_animation.riv"
                      autoplay
                      alt="Scanning_Shelf  "
                    />
                  </div>

                </div> */}
              </div>
            </div>
          </header>
          <LogoSection />
          {/* <CardRow className="container flex" /> */}
          {/* <section className="blog_1">
            <div className="container justify-content-center  px-xl-0">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-7 col-xl-6 d-flex justify-content-center">
                  <div class="container">
                    <div class="row">
                      <div class="col" style={{ "margin-bottom": "40px" }}>
                        <img
                          src="/i/shop_image_1.jpg"
                          alt=""
                          className="w-full h-100"
                          alt="shopkeeper"
                        />{" "}
                      </div>

                      <div class="col" style={{ "margin-bottom": "40px" }}>
                        <img
                          src="/i/shop_image_2.jpg"
                          alt=""
                          className="w-full h-100"
                          alt="shopkeeper"
                        />{" "}
                      </div>
                      <div class="w-100"></div>

                      <div class="col">
                        <img
                          src="/i/shop_image_3.jpg"
                          alt=""
                          className="w-full h-100"
                          alt="shopkeeper"
                        />{" "}
                      </div>
                      <div class="col">
                        <img
                          src="/i/shop_image_4.jpg"
                          alt=""
                          className="w-full h-100"
                          alt="shopkeeper"
                        />{" "}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "78px",
                        height: "78px",
                        padding: "9px",
                        left: "43%",
                        top: "43%",
                        position: "absolute",
                        "background-color": "rgba(69, 39, 160, 1)",
                        "border-radius": "10px",
                        display: "inline-flex",
                        "flex-direction": "row",
                        "align-items": "center",
                        "justify-content": "center",
                      }}
                    >
                      <img
                        src="/i/eg_center.png"
                        alt=""
                        className="w-full h-100"
                        data-aos="fade-down"
                        data-aos-delay="0"
                        alt="shopkeeper"
                      />
                    </div>
                  </div> */}
          {/* <img src="/i/shop.jpg" alt="" className="w-full h-100" data-aos="fade-down" data-aos-delay="0" alt="shopkeeper"/> */}
          {/* <div style={{"width": "474.34px", "height": "550px", "position": "relative"}}>
    <div style={{"width":"474.34px", "height": "550px", "left": "0px", "top" : "0px", "position" : "absolute", "position" : "relative"}}>
        <div style={{"width": "229.31px", "height": "328.70px", "padding-bottom" : "59px", "left" : "0px", "top" : "0px", "position": "absolute", "display": "inline-flex", "flex-direction": "row", "align-items" : "flex-start", "justify-content": "center"}}>
            <div style={{"width": "229.31px", "height": "269.86px", "padding-top": "238px", "padding-bottom": "23px", "padding-left": "3px", "padding-right": "173px", "background-color": "rgba(0, 0, 0, 0.20)", "border-top-left-radius ": "14px", "display": "flex", "flex-direction": "row", "align-items": "flex-end","justify-content": "flex-start"}}>
                <div style={{"width": "53.35px", "height": "9.03px", "background-color": "white", "border-radius": "8px"}}/>
            </div>
        </div>
        <div style={{"width" : "233.44px", "height": "315.22px", "padding-top": "46px", "left": "-0px", "top": "234.54px", "position": "absolute", "display": "inline-flex", "flex-direction": "row", "align-items": "flex-end", "justify-content": "center"}}>
            <div style={{"width": "233.31px", "height": "269.60px", "padding-top": "22px", "padding-bottom": "238px", "padding-left": "9px", "padding-right": "171px", "background-color": "rgba(0, 0, 0, 0.20)", "border-bottom-left-radius": "14px", "display": "flex", "flex-direction": "row", "align-items": "flex-start", "justify-content": "flex-start"}}>
                <div style={{"width": "53.35px", "height": "9.03px", "background-color": "white", "border-radius": "8px"}}/>
            </div>
        </div>
        <div style={{"width": "234.42px", "height": "269.86px", "padding-right": "1px", "left": "239.92px", "top": "0px", "position": "absolute", "display": "inline-flex", "flex-direction": "row", "align-items": "center", "justify-content": "center"}}>
            <div style={{"width": "233.31px", "height": "269.60px", "padding-top": "234px", "padding-bottom": "26px", "padding-left": "168px", "padding-right": "4px", "background-color": "rgba(0, 0, 0, 0.20)", "border-top-right-radius": "14px", "display":"flex", "flex-direction": "row", "align-items": "flex-end", "justify-content": "flex-end"}}>
                <div style={{"width": "61.53px", "height": "9.03px", "background-color": "white", "border-radius": "8px"}}/>
            </div>
        </div>
        <div style={{"width": "233.69px", "height": "269.83px", "left": "239.54px", "top": "280.17px", "position": "absolute", "display": "inline-flex", "flex-direction": "row", "align-items": "center", "justify-content": "center"}}>
            <div style={{"width": "233.31px", "height": "269.60px", "padding-top": "245px", "padding-bottom": "15px", "padding-left": "18px", "padding-right": "162px", "background-color": "rgba(0, 0, 0, 0.20)", "border-bottom-right-radius": "14px", "display": "flex", "flex-direction": "row", "align-items": "flex-end", "justify-content": "flex-start"}}>
                <div style={{"width": "53.35px", "height": "9.03px", "background-color": "white", "border-radius": "8px"}}/>
            </div>
        </div>
    </div>
    <div style={{"width": "78px", "height": "78px", "padding": "9px", "left": "207px", "top": "247px", "position": "absolute", "background-color": "rgba(69, 39, 160, 1)", "border-radius": "10px", "display": "inline-flex", "flex-direction": "row", "align-items": "center", "justify-content": "center"}}>
        <div style={{"width": "59.65px", "height": "59.65px", "background-color": "rgba(255, 241.79, 0, 1)", "border-radius": "8px"}}/>
    </div>
</div> */}
          {/* </div>
                <div className="col-lg-5 col-sm-6 col-xl-6  justify-content-center align-items-center">
                  <div data-aos="fade-down" data-aos-delay="250"></div>
                  <p
                    className="mb-25"
                    style={{
                      fontSize: 42,
                      fontWeight: "700",
                      lineHeight: "130%",
                      color: "rgba(33, 35, 83, 1)",
                    }}
                    data-aos="fade-down"
                    data-aos-delay="500"
                  >
                    Do you run a store?
                  </p>
                  <p
                    className="mb-50 "
                    style={{
                      fontSize: 24,
                      fontWeight: "600",
                      lineHeight: "130%",
                      color: "rgba(74.99, 92.92, 103.51, 1)",
                    }}
                    data-aos="fade-down"
                    data-aos-delay="750"
                  >
                    Do you provide free delivery in your area? Boost your sales
                    with ShopEG 🚀
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: 24,
                      fontWeight: "600",
                      lineHeight: "130%",
                      color: "rgba(74.99, 92.92, 103.51, 1)",
                      textTransform: "uppercase",
                    }}
                    data-aos="fade-down"
                    data-aos-delay="750"
                  >
                    Launch your store instantly ⚡️
                  </p>
                  <br />
                  <div
                    className="d-sm-flex justify-left"
                    data-aos="fade-down"
                    data-aos-delay="1000"
                  >
                    <a
                      href="https://shopeg.page.link/web"
                      className="mb-4 mb-sm-0 button f-16 sm"
                      target="_blank"
                      rel="noreferrer"
                      style={{ "margin-right": "12px" }}
                    >
                      {" "}
                      Launch Now!{" "}
                    </a>
                    <p> &nbsp; &nbsp; &nbsp; &nbsp; </p>
                    <a
                      href="https://wa.me/message/XUJYONCJB4VKE1"
                      className="mr-4 mb-sm-0 button f-16 sm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      WhatsApp us for Help! &nbsp;
                      <i className="fab fa-whatsapp fa-lg"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <section data-aos="fade-down " data-aos-delay="100">
            <FeaturesSection
              features={featuresData}
              appImageSrc={"/i/screenshots-1.svg"}
              overlayColor="#00000080" // Example of a semi-transparent overlay color
            />
          </section>
          <section data-aos="fade-down " data-aos-delay="100">
            <HowTo />
          </section>
          {/* <section
            className="screenshots text-center application_1"
            data-bg-src="false"
            data-bg-src-2x="false">

            <div className="container-fluid px-xl-0 color-main">
              <div className="row justify-content-center">
                <div
                  className="col-lg-7 col-sm-12 col-md-12"
                  data-aos="fade-down"
                  data-aos-delay="500"
                >
                  <img
                    src="/i/screenshots-1.svg"
                    alt=""
                    className="mt-30 mb-30 img-fluid"
                    alt="ShopEG-App"
                    // style={{
                    //   //  maxWidth: "100%", maxHeight: "40%"
                    //   margin:"20px"
                    //   }}
                  />
                </div>
              </div>
            </div>
          </section> */}
          {/* <section>
            <div className="container" >
              <div className="row">
                <div className="col-lg-12">
                  <div className="card shadow">
                    <div className="card-body">
                      <h4 className="card-title mb-5">How it works</h4>
                      <div className="hori-timeline" dir="ltr">
                        <ul className="list-inline events">
                          <li className="list-inline-item event-list" data-aos="fade-down" data-aos-delay="10">
                            <div className="px-4">
                              <div className="event-date bg-success text-light">1</div>
                              <img src="/i/find.jpg" width="160px" height="165px" alt="find nearest shops" />
                              <br /><br /><br />
                              <h5 >Find Your Nearest Store</h5>
                            </div>
                          </li>
                          <li className="list-inline-item event-list" data-aos="fade-down" data-aos-delay="10">
                            <div className="px-4">
                              <img src="/i/place.jpg" width="105px" height="165px" alt="Place order" />
                              <div className="event-date bg-success text-light">2</div>
                              <br /><br /><br />
                              <h5 >Place your Order to the Store</h5>
                            </div>
                          </li>
                          <li className="list-inline-item event-list" data-aos="fade-down" data-aos-delay="10">
                            <div className="px-4">
                              <img src="/i/deliver.jpg" width="130px" height="150px" alt="free delivery" />
                              <div className="event-date bg-success text-light">3</div>
                              <br /><br /><br />
                              <h5 >Store delivers at your doorstep for FREE!</h5>
                            </div>
                          </li>
                          <li className="list-inline-item event-list" data-aos="fade-down" data-aos-delay="10">
                            <div className="px-4">
                              <img src="/i/cod.jpg" width="160px" height="165px" alt="Payment" />
                              <div className="event-date bg-success text-light">4</div>
                              <br /><br /><br />
                              <h5 >Pay directly with Cash or UPI</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section> */}
          {/* <section className="pt-105 pb-45">
            <div className="container">
              <p
                style={{
                  fontSize: 48,
                  fontWeight: "800",
                  color: "rgba(24, 25, 31, 1)",
                }}
              >
                Features
              </p>
            </div>
            <div style={{ height: "4em" }}></div>
            <div
              className="container"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              <div class="row ">
                <div class="col-xl-8 flex-row justify-self-center">
                  <div class="row ">
                    <div class="col-2 col-sm-">
                      <div
                        style={{
                          width: 72,
                          height: 72,
                          padding: 21,
                          backgroundColor: "rgba(244, 245, 247, 1)",
                          borderRadius: 100,
                          display: "inline-flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 30,
                            height: 30,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{ width: 35, height: "100%" }}
                            src="/i/location.svg"
                            alt="location"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-10 col-sm-">
                      <div
                        style={{
                          display: "inline-flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: "700",
                            color: "rgba(24, 25, 31, 1)",
                          }}
                        >
                          Find stores around you
                        </p>

                        <p
                          style={{ fontSize: 16, color: "rgba(24, 25, 31, 1)" }}
                        >
                          Find Shops to buy Groceries, Medicines, Dairy
                          products, Clothing, Home Accessories and more around
                          you
                          <br />
                          We bring every retail shop around you in the palm of
                          your hands.
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="row ">
                    <div class="col-2 col-sm-">
                      <div
                        style={{
                          width: 72,
                          height: 72,
                          padding: 21,
                          backgroundColor: "rgba(244, 245, 247, 1)",
                          borderRadius: 100,
                          display: "inline-flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 30,
                            height: 30,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{ width: 35, height: "100%" }}
                            src="/i/order.svg"
                            alt="Orer Online"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-10 col-sm-8">
                      <div
                        style={{
                          display: "inline-flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: "700",
                            color: "rgba(24, 25, 31, 1)",
                          }}
                        >
                          Place your order
                        </p>
                        <div style={{ height: 2 }} />
                        <p
                          style={{ fontSize: 16, color: "rgba(24, 25, 31, 1)" }}
                        >
                          Send your shopping list to your nearest trusted store
                          with ease. Get it delivered from the store for free or
                          Pick it up from the store when it’s ready!
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="row ">
                    <div class="col-2 col-sm-">
                      <div
                        style={{
                          width: 72,
                          height: 72,
                          padding: 21,
                          backgroundColor: "rgba(244, 245, 247, 1)",
                          borderRadius: 100,
                          display: "inline-flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 30,
                            height: 30,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{ width: 35, height: "100%" }}
                            src="/i/chat.svg"
                            alt="Chat with shopkeeper"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-10 col-sm-8">
                      <div
                        style={{
                          display: "inline-flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: "700",
                            color: "rgba(24, 25, 31, 1)",
                          }}
                        >
                          Call/Chat with the store
                        </p>
                        <p
                          style={{ fontSize: 16, color: "rgba(24, 25, 31, 1)" }}
                        >
                          Once your order is accepted, you can chat seamlessly
                          with the store until your order is delivered or picked
                          up!
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="row ">
                    <div class="col-2 col-sm-">
                      <div
                        style={{
                          width: 72,
                          height: 72,
                          padding: 21,
                          backgroundColor: "rgba(244, 245, 247, 1)",
                          borderRadius: 100,
                          display: "inline-flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 30,
                            height: 30,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{ width: 35, height: "100%" }}
                            src="/i/shop.svg"
                            alt="Shop Online"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-10 col-sm-8">
                      <div
                        style={{
                          display: "inline-flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: "700",
                            color: "rgba(24, 25, 31, 1)",
                          }}
                        >
                          Are you a shopkeeper?
                        </p>
                        <div style={{ height: 8 }} />
                        <p
                          style={{ fontSize: 16, color: "rgba(24, 25, 31, 1)" }}
                        >
                          Start accepting orders online from your nearest
                          customers for pickup or delivery Setup your Shop on
                          ShopEG instantly! ⚡️
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 flex">
                  {" "}
                  <img
                    style={{
                      width: "320px",
                      height: "650px",
                      alignSelf: "center",
                      justifySelf: "auto",
                    }}
                    src="/i/homepage.jpg"
                    alt="ShopEG"
                  />
                </div>
              </div>
            </div>
          </section> */}
          {/* <section className="pt-105 pb-45 text-center feature_1  ">
            <div className="container px-xl-0">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <h2 className="mb-45 small" data-aos="fade-down" data-aos-delay="0">
                    Our Features
                  </h2>
                  <div className="row justify-content-center">
                    <div className="col-md-4 mb-50 " data-aos="fade-down" data-aos-delay="250">
                      <img src="/i/stores-around-me.jpg" className="feature_image" width="250" height="200" alt="Shops around me" />

                      <div className="mt-20 mb-25 f-22 bold" style={{ "font-weight": "700" }} >
                        Find stores
                        <br />around you
                      </div>
                      <div className="color-heading text-adaptive semibold">
                        Find Shops to buy Groceries, Medicines, Dairy products, Clothing, Home Accessories
                        and more around you
                        <br />
                        We bring every retail shop around you in the palm of your hands.
                      </div>
                    </div>
                    <div className="col-md-4 mb-50 " data-aos="fade-down" data-aos-delay="500">
                      <img src="/i/pick-up.jpg" className="feature_image" width="250" height="200" alt="free delivery or pick up" />

                      <div className="mt-20 mb-25 f-22 bold" style={{ "font-weight": "700" }} >
                        Place your
                        <br /> order
                      </div>
                      <div className="color-heading text-adaptive semibold">
                        Send your shopping list to your nearest trusted store with ease.
                        <br /><br /><br />
                        Get it delivered from the store for free or Pick it up from the store when it’s ready!
                      </div>
                    </div>
                    <div className="col-md-4 mb-50" data-aos="fade-down" data-aos-delay="750">
                      <img src="/i/home.jpg" className="feature_image" width="210" height="200" alt="chat with the shop" />
                      <div className="mt-20 mb-25 f-22 bold " style={{ "font-weight": "700" }} >
                        Chat with the
                        <br /> store
                      </div>
                      <div className="color-heading text-adaptive semibold">
                        Once your order is accepted, you can chat seamlessly with the store until your order is
                        delivered or picked up!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-50 pb-  text-center feature_1">
            <div className="container px-xl-0">
              <div className="row justify-content-center">
                <div className="col-xl-10">

                  <div className="row justify-content-center">
                    <div className="col-md-4 mb-50 " data-aos="fade-down" data-aos-delay="250">

                      <img src="/i/shopkeeper.jpg" className="feature_image" width="260" height="200" alt="shopkeeper" />

                      <div className="mt-20 mb-25 f-22 bold" style={{ "font-weight": "700" }} >
                        Are you a
                        <br />shopkeeper?
                      </div>
                      <div className="color-heading text-adaptive semibold">
                        Start accepting orders online from your nearest customers for pickup or delivery
                        <br /><br />
                        Setup your Shop on ShopEG instantly!
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <div className="screenshots pb-00 text-center application_1">
            <section id="about" className="about">
              <div
                className="container pt-10"
                data-aos="fade-down "
                data-aos-delay="100"
              >
                <div
                  className="bg-success p-3 mb-5 rounded"
                  style={{ "border-radius": "4rem" }}
                >
                  <div className="row justify-content-center"> */}
          {/* <h5 style={{"color": "white"}}>Built For </h5> */}
          {/* <div className="col-lg-11">
                      <div className="row ">
                        <div className="col-lg-4 col-md-5 col-12 align-items-md-stretch">
                          <div className="count-box py-5">
                            <span
                              data-toggle="counter-up"
                              style={{
                                "font-size": "16px",
                                "font-weight": "800",
                                color: "#fff",
                              }}
                            >
                              Save 96 hours
                            </span>
                            <p
                              style={{
                                "font-size": "16px",
                                "font-weight": "800",
                                color: "#fff",
                              }}
                            >
                              in-store shopping/year
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-12 align-items-md-stretch">
                          <div className="count-box pb-5 pt-0 pt-lg-5">
                            <span
                              data-toggle="counter-up"
                              style={{
                                "font-size": "16px",
                                "font-weight": "800",
                                color: "#fff",
                              }}
                            >
                              Save 10,000₹{" "}
                            </span>
                            <p
                              style={{
                                "font-size": "16px",
                                "font-weight": "800",
                                color: "#fff",
                              }}
                            >
                              in Delivery fee/year
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-12 align-items-md-stretch">
                          <div className="count-box py-5">
                            <span
                              data-toggle="counter-up"
                              style={{
                                "font-size": "16px",
                                "font-weight": "800",
                                color: "#fff",
                              }}
                            >
                              Across 30+
                            </span>
                            <p
                              style={{
                                "font-size": "16px",
                                "font-weight": "800",
                                color: "#fff",
                              }}
                            >
                              Categories
                            </p>
                          </div>
                        </div> */}
          {/* <div className="col-lg-3 col-md-5 col-12 align-items-md-stretch">
                      <div className="count-box pb-5 pt-0 pt-lg-5">
                      
                        <span data-toggle="counter-up"
                          style={{"font-size": "16px","font-weight": "800","color":"#fff"}}>1</span>
                        <p style={{"font-size": "16px","font-weight": "800","color":"#fff"}}>Country</p>
                      </div>
                    </div> */}
          {/* </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div> */}
          {/* <section style={{margin: "auto", display: "flex",
                    justifyContent: "start",
                    alignItems: "center", width:"98%"}}>
          <ReviewSection2/>
          </section>
          */}
          <section data-aos="fade-down " data-aos-delay="100">
            <ReviewSection />
          </section>
          <section>
            <div>
              <h1
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Pricing
              </h1>
              ;
              <PricingSection />
            </div>
          </section>
          <section
            className="pt-0  container"
            // style={{
            //   "border-radius": "4em",
            //   background: "linear-gradient(83deg, #4527A0,#8862F1, #4527A0 )",

            // }}
          >
            <br />
            <div
              className="call_to_action_1"
              style={{
                "border-radius": "2em",
                background: "linear-gradient(83deg, #4527A0,#8862F1, #4527A0 )",
              }}
            >
              <div className="container px-xl-0 ">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-5 col-md-6 color-white ">
                    {/* <div style={labelStyle}>ShopEG</div>
                    <br /> */}
                    <h1
                      data-aos="fade-down"
                      data-aos-delay="0"
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontSize: "42px",
                      }}
                    >
                      <br />
                      Expand your <i>dukaan</i> online. Start scanning now!
                    </h1>

                    <div data-aos="fade-down" data-aos-delay="500">
                      <a
                        href="https://api.whatsapp.com/send?phone=918618563552&text=Hi%2C%20I%20am%20interested%20in%20joining%20ShopEG"
                        style={{
                          display: "inline-block",
                          borderRadius: "10px",
                          backgroundColor: "#4CAF50",
                          color: "white",
                          padding: "15px",
                          fontSize: "22px",
                          textDecoration: "none", // Remove underline style
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Join ShopEG
                      </a>
                    </div>
                  </div>
                  {/* <div className="col-xl-1  col-lg-1 col-md-1"></div> */}
                  <StyledCol className="col-xl-6 col-lg-6 col-sm-6 col-6">
                    <StyledImage src="i/shop.png" alt="Order from home" />
                  </StyledCol>
                </div>
              </div>
            </div>
          </section>
          <br /> <br />
          {/* <section>
  <div className="socials container-flex">
    <div className="row justify-content-center">
      <div className="col-12 col-sm-12 col-md-8">
         <h2 style={{ "color": "#333","margin-bottom": "40px", textAlign: "center"}}> User guides and walkthroughs</h2>
        <iframe
          style={{ display: "flex" }}
          width="100%"
          height="515"
          src="https://www.youtube.com/embed/videoseries?list=PLlYzxWMAXgkzdr-cLxveHDtYer1eDZ3UW"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</section> */}
          <footer
            className="pt-70 pb-20  text-center footer_1 "
            style={{
              background: "linear-gradient(120deg, #4527A0,#8862F1, #4527A0 )",
            }}
          >
            <div className="container px-xl-0">
              <div className="row justify-content-between align-items-center lh-40 links">
                <div
                  className="col-lg-6 col-sm-6 text-sm-right text-lg-left order-1 order-lg-0"
                  data-aos="fade-down"
                  data-aos-delay="250"
                >
                  <a
                    href="https://wa.me/message/XUJYONCJB4VKE1"
                    alt="Contact Sheet"
                    className="link mx-15 color-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                  <a href="/privacy.html" className="link mx-15 color-white">
                    Privacy policy
                  </a>
                  <a
                    href="/seller-agreement.html"
                    className="link mx-15 color-white"
                  >
                    Terms and Agreement
                  </a>
                </div>
                <div
                  className="col-lg-4 col-sm-6 text-sm-left text-lg-right order-2 order-lg-0"
                  data-aos="fade-down"
                  data-aos-delay="250"
                >
                  <a
                    href="https://wa.me/message/XUJYONCJB4VKE1"
                    className="link mr-15 color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="WhatsApp"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                  <a
                    href="https://twitter.com/ShopEG_IN"
                    className="mx-15 link color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Twitter"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/ShopEGapp/"
                    className="mx-15 link color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Facebook"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/shopeg.in/"
                    className="ml-15 link color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <i className="fab fa-instagram "></i>
                  </a>
                </div>
              </div>
              <div
                className="mb-10 mb-lg-0 col-lg-auto order-0"
                data-aos="fade-down"
                data-aos-delay="0"
              >
                <a className="link logo color-main" href="/">
                  <div className="row">
                    {" "}
                    <img
                      src="/i/eg.png"
                      height="32"
                      width="30"
                      alt="ShopEG logo"
                    />
                    <p className="color-white" style={{ marginLeft: "4px" }}>
                      {" "}
                      ShopEG
                    </p>
                  </div>
                </a>
              </div>
              <div className="row justify-content-center color-white">
                <div
                  className="mt-10 col-xl-4 col-lg-5 col-md-6 col-sm-8"
                  data-aos="fade-down"
                  data-aos-delay="0"
                >
                  <div className="color-white text-adaptive">
                    © Copyright Sparketernal Technologies Pvt Ltd. All Rights
                    Reserved
                    <span>
                      <p></p>
                      <p>
                        {" "}
                        Mahalakshmi Chambers, 9, MG Road, Opp. Trinity Metro
                        Station, Bangalore, 560001
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Home;

import React from "react";
import "aos/dist/aos.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { Link, Redirect } from "react-router-dom";

const MenuKan = () => {
  return (
    <>
      {/* <BrowserView > */}
      <nav class="navbar navbar-default navbar-fixed-top">
        <div class="container ">
          <a
            className=" logo color-white "
            style={{ marginLeft: "-11.299%" }}
            href="/"
          >
            <div className="row ">
              {" "}
              <img
                className="img-fluid"
                src="/i/eg.png"
                style={{ height: "38px", width: "38px" }}
                alt="ShopEG-Logo"
              />
              <h4
                style={{
                  "font-size": "22px",
                  "font-weight": "800",
                  marginLeft: "8px",
                  color: "#232323",
                  marginBottom: "2px",
                }}
              >
                ಶಾಪ್ ಈಜಿ
              </h4>
            </div>
          </a>

          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="false"
              aria-controls="navbar"
            >
              <span class="sr-only"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div id="navbar" class="collapse navbar-collapse">
            <ul className="nav navbar-nav navbar-right ml-auto flex-row">
              <li>
                <Link to="/" style={{ fontSize: "16px", color: "#4CAF50" }}>
                  ಹೋಮ್
                </Link>
              </li>
              <li>
                <Link
                  className="link-nav-btn"
                  to="/AboutUs-Kan"
                  style={{ fontSize: "16px", color: "#898A8D" }}
                >
                  ನಮ್ಮ ಬಗ್ಗೆ
                </Link>
              </li>
              <li>
                <a
                  className="link-nav-btn"
                  target="_blank"
                  rel="noreferrer"
                  href="https://wa.me/message/XUJYONCJB4VKE1"
                  style={{ fontSize: "16px", color: "#898A8D" }}
                >
                  ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ
                </a>
              </li>
              <li> " "</li>
              <li className="download1" style={{ color: "232323" }}>
                <a
                  href="https://d2ctrust.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  D2C Brand
                </a>
              </li>

              <li>
                <div className="btn-bg btn-group rounded btn-group-sm">
                  <Link className="lang-btn btn-group-sm btn-primary" to="/">
                    English
                  </Link>
                  <Link
                    className="lang-btn btn-group-sm btn-primary active"
                    to="/index-kan"
                  >
                    ಕನ್ನಡ
                  </Link>
                  <Link
                    className="lang-btn btn-group-sm btn-primary"
                    to="/index-hin"
                  >
                    हिन्दी
                  </Link>
                </div>
              </li>
              {/* <li className="download"><a href="https://shopeg.page.link/web" target="_blank" rel="noreferrer" >ಡೌನ್ಲೋಡ್ ಮಾಡಿ </a></li> */}
            </ul>
          </div>
        </div>
      </nav>
      {/* </BrowserView>
    <MobileView>
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span class="sr-only"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
      </div>
    </MobileView> */}
    </>
  );
};

export default MenuKan;

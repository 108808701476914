// AboutUs.js

import React from "react";
import "./AboutUs.css"; // Ensure this CSS file is in the same folder as your component
import shopImage from "../assets/shop3.svg";
import MenuAboutUsKan from "./MenuAboutUsKan";
import basanthImage from "../assets/images/team/basanth.png";
import ranjanaImage from "../assets/images/team/ranjana.png";
import { Link } from "react-router-dom";

const AboutUsKan = () => {
  const teamMembers = [
    {
      name: "ಬಸಂತ್",
      title: "ಕೋ-ಫೋಂಡರ್",
      imageUrl: basanthImage,
      linkedin: "https://www.linkedin.com/in/basanthnverma/",
    },
    {
      name: "ರಂಜನಾ",
      title: "ಕೋ-ಫೋಂಡರ್",
      imageUrl: ranjanaImage,
      linkedin: "https://www.linkedin.com/in/ranjana-verma/",
    },

    // { name: 'Vijai Chandra Prasad', title: 'Founding Engineer', imageUrl: 'https://m.media-amazon.com/images/I/71VosPIHljL._SX679_.jpg' },
  ];

  return (
    <div>
      <MenuAboutUsKan />
      <br />
      <br />
      <div className="about-container">
        <div className="our-story">
          <h1>ನಮ್ಮ ಬಗ್ಗೆ</h1>
          <p>
            ಶಾಪ್ ಈಜಿ ಒಂದು ಕಿರಣ ಅಗ್ರಗಣಕ ಪ್ಲ್ಯಾಟ್‌ಫಾರಂ, ಭಾರತದ ಎಲ್ಲಾ ಭಾಗಗಳಲ್ಲಿ
            ಸ್ಥಾನಾಂತರಿಸಲು ಇಚ್ಛಿಸುವ ಒಂದು ಅದ್ಭುತ ಉದ್ದೇಶವನ್ನು ಹೊಂದಿದೆ. ನಾವು ಇದನ್ನು
            ಓಪನ್ ನೆಟ್ವರ್ಕ್ ಫರ್ ಡಿಜಿಟಲ್ ಕಾಮರ್ಸ್ (ONDC) ಗೆ ಈ ಅಂಗವಾಗಿ ಸ್ಥಾನಾಂತರಿಸಲು
            ಲಕ್ಷಿಸಿದ್ದೇವೆ, ಈ ಉದ್ದೇಶಕ್ಕೆ ಸಂಕೀರ್ಣವಾಗಿ ಅನುಗುಣವಾಗಿದೆ. ಭಾರತದ ಖಾಸಗಿ
            ವಿಪಣಿ ಭೂಮಿಯನ್ನು ಡಿಜಿಟಲ್ ಮಾಡುವ ನಮ್ಮ ಉದ್ದೇಶಕ್ಕೆ ಇದು ಪೂರ್ಣವಾಗಿ
            ಅನುಗುಣವಾಗಿದೆ.
            <br />
            <br />
            ನಮ್ಮ ವಿಶಿಷ್ಟ ಮಾರುಕಟ್ಟೆ ಮೌಲ್ಯವಿದ್ದೀತು ನಮ್ಮ AI ಆಧಾರಿತ ಕ್ಯಾಟಲಾಗ್
            ಡಿಜಿಟೈಸೇಷನ್ ಸಿಸ್ಟಮ್ ನಲ್ಲಿ. ಈ ತಂತ್ರಜ್ಞಾನ ನಮಗೆ 20-30 ನಿಮಿಷಗಳಲ್ಲಿ
            ಹೆಚ್ಚಿನ 1000 ವಸ್ತುಗಳ ಕ್ಯಾಟಲಾಗ್ ಅನ್ನು ಜೋಡಿಸಲು ಅನುಮತಿಸಲಾಗಿದೆ, ಸ್ಟೋರ್
            ಡಿಜಿಟಲ್ ಪ್ಲ್ಯಾಟ್‌ಫಾರಂಗೆ ಸ್ವಿಚ್ ಮಾಡಲು ಅವಕಾಶ ಮತ್ತು ಶ್ರಮವನ್ನು ಕಡಿಮೆ
            ಮಾಡುತ್ತದೆ.
            <br />
            <br />
            ನಾವು ನಮ್ಮ ಪರಿಹಾರದಿಂದ ಕೂಡಿದಂತೆ ನಾವು ONDC ಉದ್ಯಮದ ಸಹಾಯದಿಂದ ಭಾರತದ ಖಾಸಗಿ
            ವಿಪನಿ ಉದ್ಯಮದ ಕೊನೆಯ ಬೆನ್ನುಮೂಲ ಖಾತೆ ನಿರ್ಮಾಣವನ್ನು ಹೆಚ್ಚಿಸಬಹುದು ಎಂಬ
            ನಂಬಿಕೆಯನ್ನು ಹೊಂದಿದ್ದೇವೆ. ಈ ವಿಧಾನದಿಂದ, ನಾವು ಈ ಅಂಗಡಿಗಳನ್ನು ಆನ್‌ಲೈನ್
            ಮಾಡುವುದರ ಮೂಲಕ ಹೆಚ್ಚಿನ ಗ್ರಾಹಕ ಸಾಗರವನ್ನು ಪಡೆಯಲು, ಅವುಗಳ
            ಕಾರ್ಯಚಟುವಟಿಕೆಯನ್ನು ಸುಧಾರಿಸಲು ಮತ್ತು ಡಿಜಿಟಲ್ ಆರ್ಥಿಕ ಸಂಸ್ಥೆಯಲ್ಲಿ
            ಸಕ್ರಿಯವಾಗಿ ಪ್ರತಿಸ್ಪರ್ಧಿಸಲು ಸಹಾಯ ಮಾಡಬಹುದು.
          </p>
          <h1>ನಮ್ಮ ಉದ್ದೇಶ</h1>
          <p>
            ನಾವು ಭಾರತದ ಸರ್ವಾಧಿಕ ಸುಧಾರಕರಾಗಲು ಇಲ್ಲಿ ಇದ್ದೇವೆ, ನಮ್ಮ ಸಾಗರದ ಅನೇಕ
            ಕಿರಾಣಾ ಅಂಗಡಿಗಳು ಸಮೃದ್ಧಿ ಮತ್ತು ಸಮೃದ್ಧಿಗಾಗಿ, ಡಿಜಿಟಲ್ ಬದಲಾವಣೆಯನ್ನು
            ನಡೆಸುವುದು ಮತ್ತು ಅವರ ಅನುಗ್ರಹದ ನಿರ್ವಹಣೆ ಮತ್ತು ಅವರ ಸಮೀಪದ ಮತ್ತು
            ಹೊರಗಿನವರು ಸಂಪರ್ಕ ಸಾಧಿಸುವ ಮೂಲಕ. ನಾವು ಭಾರತದಲ್ಲಿಯೂ ಸಹ ಸಣ್ಣ ಕಿರಣ
            ಅಂಗಡಿಗಳು ನಡೆಯುತ್ತಿವೆ ಮತ್ತು ಅವು ತಮ್ಮ ಗ್ರಾಹಕರ ಸಂಗಮವನ್ನು ಹೇಗೆ
            ನಡೆಸುತ್ತವೆ ಬದಲಾವಣೆಗಳು ಮತ್ತು ಡಿಜಿಟಲ್ ಸಮಾಧಾನಗಳಿಗೆ ಹೆಚ್ಚಿನ
            ಕಾರಣವಾಗಿದ್ದೇವೆ. ನಮ್ಮ ಪ್ರವಾಸ ನಮ್ಮನ್ನು ನಿರಂತರವಾಗಿ ಆವಿಷ್ಕರಿಸುತ್ತಿದೆ
            ಮತ್ತು ನಾವು ದೇಶಾದ್ಯಾಂತ ವೃದ್ಧಿಯಾಗುತ್ತಿರುವ ಡಿಜಿಟಲ್-ಸಾಧ್ಯತೆಯ ಜಾಲವನ್ನು
            ಬೆಳೆಸುತ್ತಿದೆ. ಮುಂದುವರೆದಾಗ, ನಾವು ಸ್ಥಾನಿಕ ಅಂಗಡಿ ಮಾಲ್ಕೆಗಾರರ ​​ಜೀವನಕ್ಕೆ,
            ಅವರ ಸಮುದಾಯಕ್ಕೆ, ಹಾಗೂ ಭಾರತದ ಆರ್ಥಿಕ ಸಮೃದ್ಧಿಗೆ ಶಾಶ್ವತ ಮತ್ತು ಸ್ಥಾಯಿ ಹಾನಿ
            ಉಂಟಾಗುವ ಉದ್ದೇಶವನ್ನು ಸಾಧಿಸಲು ಲಕ್ಷಿಸಿದ್ದೇವೆ.
          </p>
        </div>
        {/* <div className="our-story">
          <h1>Why We Are Here</h1>
          <p>
            We're here to become the leading enabler of growth and prosperity
            for small grocery stores across India, driving digital
            transformation and connecting them to customers in their
            neighbourhood and beyond.
          </p>
          <p></p>
        </div> */}
        <div className="connecting-kiranas">
          <img src={shopImage} alt="Kirana store owner" />
          <h1>
            ಕಿರಾನಾ ಸ್ಟೋರ್‌ಗಳ ಸಾಮರ್ಥ್ಯವನ್ನು ಡಿಜಿಟೈಸ್ ಮಾಡಿ, ಸಂಘಟಿಸಿ ಮತ್ತು
            ವಿಸ್ತರಿಸಿ
          </h1>
        </div>
        <div className="team-section">
          <h1>ನಮ್ಮ ಟಿಮ್ ಅನ್ನು ಭೇಟಿ ಮಾಡಿ</h1>
          <div className="team-members">
            {teamMembers.map((member) => (
              <div className="team-member" key={member.name}>
                <img src={member.imageUrl} alt={member.name} />
                <h3>{member.name}</h3>
                <p>{member.title}</p>
                <a
                  href={member.linkedin}
                  className="link color-black"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="LinkedIn"
                >
                  <i
                    class="fab fa-linkedin-in"
                    style={{ fontSize: "24px", width: "20px", height: "20px" }}
                  ></i>
                </a>
                {/* <i className="team-icon"></i> */}
              </div>
            ))}
          </div>
        </div>
      </div>

      <footer
        className="pt-70 pb-20  text-center footer_1 "
        style={{
          background: "linear-gradient(120deg, #4527A0,#8862F1, #4527A0 )",
        }}
      >
        <div className="container px-xl-0">
          <div className="row justify-content-between align-items-center lh-40 links">
            <div
              className="col-lg-6 col-sm-6 text-sm-right text-lg-left order-1 order-lg-0"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <a
                href="https://wa.me/message/XUJYONCJB4VKE1"
                alt="Contact Sheet"
                className="link mx-15 color-white"
                target="_blank"
                rel="noreferrer"
              >
                ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ
              </a>
              <a href="/privacy.html" className="link mx-15 color-white">
                ಗೌಪ್ಯತಾ ನೀತಿ
              </a>
              <a
                href="/seller-agreement.html"
                className="link mx-15 color-white"
              >
                ನಿಯಮಗಳು ಮತ್ತು ಒಪ್ಪಂದ
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 text-sm-left text-lg-right order-2 order-lg-0"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <a
                href="https://wa.me/message/XUJYONCJB4VKE1"
                className="link mr-15 color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="WhatsApp"
              >
                <i className="fab fa-whatsapp"></i>
              </a>
              <a
                href="https://twitter.com/ShopEG_IN"
                className="mx-15 link color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.facebook.com/ShopEGapp/"
                className="mx-15 link color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
              <a
                href="https://www.instagram.com/shopeg.in/"
                className="ml-15 link color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram "></i>
              </a>
            </div>
          </div>
          <div
            className="mb-10 mb-lg-0 col-lg-auto order-0"
            data-aos="fade-down"
            data-aos-delay="0"
          >
            <a className="link logo color-main" href="/">
              <div className="row">
                {" "}
                <img src="/i/eg.png" height="32" width="30" alt="ShopEG logo" />
                <p className="color-white" style={{ marginLeft: "4px" }}>
                  {" "}
                  ಶಾಪ್ ಈಜಿ
                </p>
              </div>
            </a>
          </div>
          <div className="row justify-content-center color-white">
            <div
              className="mt-10 col-xl-4 col-lg-5 col-md-6 col-sm-8"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              <div className="color-white text-adaptive">
                © ಕೃತಿಸ್ವಾಮ್ ಸ್ಪಾರ್ಕ್ ಇಟರ್ನಲ್ ಟೆಕ್ನಾಲಜೀಸ್ ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್.
                ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ
                <span>
                  <p></p>
                  <p>
                    {" "}
                    No. 65, 83rd Cross, 1st Stage Kumaraswamy Layout, Bangalore,
                    Karnataka, 560078
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutUsKan;

import React from "react";
// import AOS from "aos";
import "aos/dist/aos.css";
import CardRowKan from "../layout/cardRowKan";
import MenuKan from "./MenuKan";
import HeroSectionKan from "../layout/heroKan";
import styled from "styled-components";
import FeaturesSectionKan from "../layout/featuresSectionKan";
import PricingSectionKan from "../layout/PricingSectionKan";
import scanIcon from "../assets/icons/scan.svg";
import bagIcon from "../assets/icons/bag.svg";
import increaseIcon from "../assets/icons/money-increase.svg";
import commissionIcon from "../assets/icons/low-commission.svg";
import HowToKan from "../layout/HowToKan";
import ReviewSectionKan from "../layout/reviewSectionKan";

const StyledCol = styled.div`
  display: flex;
  justify-content: center;
  height: 350px;
  width: 320px;
  @media (max-width: 768px) {
    height: 300px;
    width: 90%;
  }
`;

const StyledImage = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 10px;
`;

const labelStyle = {
  position: "absolute",
  top: "20px",
  left: "20px",
  backgroundColor: "#FFFFFF", // Label background color
  color: "#4CAF51", // Text color
  fontSize: "22px", // Adjusted font size
  fontWeight: 600, // Adjusted font weight
  padding: "12px 20px",
  borderRadius: "10px",
  boxShadow: "0px 4px 8px rgba(32, 32, 32, 0.1)",
};

const homePage = ``;

class HomeKan extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    //   return (
    //     <div className="content" dangerouslySetInnerHTML={{__html: homePage}}></div>
    // );
    // return (
    //   <Redirect to="i" />
    // )

    const featuresData = [
      {
        id: 1,
        title: "ಕ್ಯಾಮೆರಾವನ್ನು ಬಳಸಿಕೊಂಡು ಉತ್ಪನ್ನಗಳನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
        description:
          "ನಮ್ಮ ಕ್ಯಾಮರಾ ಆಧಾರಿತ AI ಬಳಸಿಕೊಂಡು ನಿಮ್ಮ ಅಂಗಡಿಯಲ್ಲಿ ಉತ್ಪನ್ನಗಳನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ ಮತ್ತು ಆನ್‌ಲೈನ್ ಕ್ಯಾಟಲಾಗ್ ಅನ್ನು ತಕ್ಷಣವೇ ರಚಿಸಿ",
        image: scanIcon,
      },
      {
        id: 2,
        title: "ಆದೇಶಗಳನ್ನು ಪಡೆಯಿರಿ",
        description:
          "ಯಾವುದೇ ಮಾರ್ಕೆಟಿಂಗ್ ಪ್ರಯತ್ನಗಳಿಲ್ಲದೆ ಆದೇಶಗಳನ್ನು ಸ್ವೀಕರಿಸಿ. ShopEG ನಲ್ಲಿ ಆರ್ಡರ್‌ಗಳನ್ನು ಸುಲಭವಾಗಿ ನಿರ್ವಹಿಸಿ",
        image: bagIcon,
      },
      {
        id: 3,
        title: "ಕಡಿಮೆ ಕಮಿಷನ್",
        description:
          "ಶಾಪ್ ಈಜಿನಲ್ಲಿ ಕನಿಷ್ಠ ಶುಲ್ಕಗಳೊಂದಿಗೆ ಪ್ರತಿ ಆರ್ಡರ್‌ನಲ್ಲಿ ಗರಿಷ್ಠ ಗಳಿಕೆ",
        image: commissionIcon,
      },
      {
        id: 4,
        title: "ಹೆಚ್ಚು ಮಾರಾಟ",
        description:
          "ONDC ಮೂಲಕ ಶಾಪ್ ಈಜಿ ಯೊಂದಿಗೆ ಹೆಚ್ಚಿದ ಮಾರಾಟದ ಸಾಮರ್ಥ್ಯವನ್ನು ಅನ್‌ಲಾಕ್ ಮಾಡಿ, ವಿವಿಧ ಪಿನ್‌ಕೋಡ್‌ಗಳಲ್ಲಿ ಗ್ರಾಹಕರಿಗೆ ಸೇವೆ ಸಲ್ಲಿಸಿ.",
        image: increaseIcon,
      },
    ];

    return (
      <div className="home">
        <div>
          <MenuKan style={{ background: "#F7F7F7" }} />
          <br />
          <header className="pt-40 pb-60 header_1 ">
            <div className="container hero ">
              <div
                className="row align-items-center "
                data-aos="fade-down"
                data-aos-delay="10"
              >
                <HeroSectionKan
                  className="container"
                  data-aos="fade-down"
                  data-aos-delay="10"
                />
              </div>
            </div>
          </header>
          <section data-aos="fade-down " data-aos-delay="100">
            <FeaturesSectionKan
              features={featuresData}
              appImageSrc={"/i/screenshots-1.svg"}
              overlayColor="#00000080" // Example of a semi-transparent overlay color
            />
          </section>
          <section data-aos="fade-down " data-aos-delay="100">
            <HowToKan />
          </section>
          <section data-aos="fade-down " data-aos-delay="100">
            <ReviewSectionKan />
          </section>
          <section>
            <div>
              <h1
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                ಬೆಲೆ ನಿಗದಿ
              </h1>
              ;
              <PricingSectionKan />
            </div>
          </section>
          <section
            className="pt-20  container"
            // style={{
            //   "border-radius": "4em",
            //   background: "linear-gradient(83deg, #4527A0,#8862F1, #4527A0 )",

            // }}
          >
            <br />
            <div
              className="call_to_action_1"
              style={{
                "border-radius": "2em",
                background: "linear-gradient(83deg, #4527A0,#8862F1, #4527A0 )",
              }}
            >
              <div className="container px-xl-0">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-5 col-md-6 color-white ">
                    {/* <div style={labelStyle}>ಶಾಪ್ ಈಜಿ</div>
                    <br /> */}
                    <h1
                      data-aos="fade-down"
                      data-aos-delay="0"
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontSize: "42px",
                      }}
                    >
                      ನಿಮ್ಮ ಅಂಗಡಿಯನ್ನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ವಿಸ್ತರಿಸಿ. ಈಗ ಸ್ಕ್ಯಾನ್
                      ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿ!
                    </h1>

                    <div data-aos="fade-down" data-aos-delay="500">
                      <a
                        href="https://beyondseek.slack.com/archives/D01PP067E4R/p1692625916414519"
                        style={{
                          display: "inline-block",
                          borderRadius: "10px",
                          backgroundColor: "#4CAF50",
                          color: "white",
                          padding: "15px",
                          fontSize: "22px",
                          textDecoration: "none", // Remove underline style
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        ಶಾಪ್ ಈಜಿ ಸೇರಿ
                      </a>
                    </div>
                  </div>
                  <StyledCol className="col-xl-6 col-lg-6">
                    <StyledImage src="i/shop.png" alt="Order from home" />
                  </StyledCol>
                </div>
              </div>
            </div>
          </section>
          <br /> <br />
          <footer
            className="pt-70 pb-20  text-center footer_1 "
            style={{
              background: "linear-gradient(120deg, #4527A0,#8862F1, #4527A0 )",
            }}
          >
            <div className="container px-xl-0">
              <div className="row justify-content-between align-items-center lh-40 links">
                <div
                  className="col-lg-6 col-sm-6 text-sm-right text-lg-left order-1 order-lg-0"
                  data-aos="fade-down"
                  data-aos-delay="250"
                >
                  <a
                    href="https://wa.me/message/XUJYONCJB4VKE1"
                    alt="Contact Sheet"
                    className="link mx-15 color-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ
                  </a>
                  <a href="/privacy.html" className="link mx-15 color-white">
                    ಗೌಪ್ಯತಾ ನೀತಿ
                  </a>
                  <a
                    href="/seller-agreement.html"
                    className="link mx-15 color-white"
                  >
                    ನಿಯಮಗಳು ಮತ್ತು ಒಪ್ಪಂದ
                  </a>
                </div>
                <div
                  className="col-lg-4 col-sm-6 text-sm-left text-lg-right order-2 order-lg-0"
                  data-aos="fade-down"
                  data-aos-delay="250"
                >
                  <a
                    href="https://wa.me/message/XUJYONCJB4VKE1"
                    className="link mr-15 color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="WhatsApp"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                  <a
                    href="https://twitter.com/ShopEG_IN"
                    className="mx-15 link color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Twitter"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/ShopEGapp/"
                    className="mx-15 link color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Facebook"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/shopeg.in/"
                    className="ml-15 link color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <i className="fab fa-instagram "></i>
                  </a>
                </div>
              </div>
              <div
                className="mb-10 mb-lg-0 col-lg-auto order-0"
                data-aos="fade-down"
                data-aos-delay="0"
              >
                <a className="link logo color-main" href="/">
                  <div className="row">
                    {" "}
                    <img
                      src="/i/eg.png"
                      height="32"
                      width="30"
                      alt="ShopEG logo"
                    />
                    <p className="color-white" style={{ marginLeft: "4px" }}>
                      {" "}
                      ಶಾಪ್ ಈಜಿ
                    </p>
                  </div>
                </a>
              </div>
              <div className="row justify-content-center color-white">
                <div
                  className="mt-10 col-xl-4 col-lg-5 col-md-6 col-sm-8"
                  data-aos="fade-down"
                  data-aos-delay="0"
                >
                  <div className="color-white text-adaptive">
                    © ಕೃತಿಸ್ವಾಮ್ ಸ್ಪಾರ್ಕ್ ಇಟರ್ನಲ್ ಟೆಕ್ನಾಲಜೀಸ್ ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್.
                    ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ
                    <span>
                      <p></p>
                      <p>
                        {" "}
                        No. 65, 83rd Cross, 1st Stage Kumaraswamy Layout,
                        Bangalore, Karnataka, 560078
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default HomeKan;

import React, {useEffect, useState} from 'react';
import Offers from "./offers/Offers";
import InfoCard from "./info/InfoCard";
import ProductsCard from "./products/ProductsCard";
import Footer from "./footer/Footer";
import Header from "./Header/Header";
import {isMobile} from "../../utils/utils";
import logo from "../../assets/offers/logo.png";

import {firebaseAnalytics} from "../../utils/firebase_config";
import Overlay from "./Overlay/Overlay";

const ShopLandingS = (props) => {


    const getShopData = ()  => {
        firebaseAnalytics.logEvent( "SHOP_PAGE_VIEW");
        const doc =  fetch('https://prod-api.shopeg.in/api/client/shop/'+ window.location.pathname.split('/')[2])
        doc.then(result => {
            result.json().then(data=>{
                console.log('data',data?.result?.data);
                if(data?.result?.data){
                    const response = fetch('https://prod-api.shopeg.in/api/client/shop/shopDetails/'+ data.result.data.shopSlug)
                    response.then(result => {
                        result.json().then(data=>{
                            console.log('data',data?.result?.data);
                            if(data?.result?.data){
                                setIsShopLaoded(true);
                            }
                            else{
                                setIsShopLaoded(false);
                            }
                            setShopData(data?.result?.data);
                            getProducts(data?.result?.data?.id);
                        });
                    })
                    setIsShopLaoded(true);

                }
                else{

                    setIsShopLaoded(false);
                }


            });
        })

        console.log("doc",(doc));



    };

    const getProducts = (shopId) => {
        const productsResponse = fetch('https://prod-api.shopeg.in/api/client/inventory/shopHighlights/' + window.location.pathname.split('/')[2])
        productsResponse.then(result => {
            console.log('result',result.json().then(data=>{
                console.log('Shop products',data.result.data)
                setShopProducts(data.result.data.products)
            }));
        })
    };


    useEffect(()=>{
        getShopData();
    },[]);

    const [shopData,setShopData] =  useState({});
    const [shopProducts,setShopProducts] =  useState([]);
    const [isShopLaoded,setIsShopLaoded] =  useState(true);

    return (
        <div>
            {shopData?.id ?
                <div>
                    <Header {...props} shopData={shopData} />
                    <div className='shopInfo'>
                        {/*<h1 className={'shopName'}>{shopData.shop_name}</h1>*/}
                        <img src={shopData?.shop_image}/>
                    </div>
                    <InfoCard shopData={shopData}/>
                    <Offers shopData={shopData}/>
                    <div className='productSection'>
                        <div className='productHeader'><h2 className='heading'>New In Store</h2></div>
                        <div className='productsWrapper'>
                            {shopProducts.map(product => {
                                return <ProductsCard product={product}/>
                            })}
                        </div>
                    </div>
                    {isMobile() &&
                    <div className='stickyDownload'><a className="downloadButtonFooter" href={shopData.link}>
                        Order Now
                    </a>
                    </div>
                    }
                    <Footer shopData={shopData}/>

                </div> : !isShopLaoded &&
                <div className='notFound'>
                    <img src={logo}/>
                    <h2>Shop Not Found</h2>
                </div>


            }

        </div>
    );

};

export default ShopLandingS;

import React from 'react';
import {BrowserView, MobileView} from "react-device-detect";
import * as firebase from "firebase";
import {firebaseAnalytics} from "../../../utils/firebase_config";

const Footer = ({shopData}) => {

    return (
        <div className='footer' id='appDownload'>
            <h2 style={{textTransform:"capitalize"}}>
                {shopData.shop_name}
            </h2>
            <h4 style={{marginBottom:20,textAlign:'center'}}>
                {shopData?.addressData?.address }
                {shopData?.addressData?.address2 }
            </h4>

            <BrowserView style={{background:'white',padding:20}}>
                <a rel="ShopEG Playstore"
                   href="https://play.google.com/store/apps/details?id=com.beyondseek.shopez.cust&referrer=utm_source%3Dshopeg.in"
                   target="_blank" rel="noreferrer" onClick={()=>{
                    firebaseAnalytics.logEvent( "SHOP_PAGE_CLICK_PLAY_STORE_ICON");
                }} >
                    <img src="/i/playstore.jpg" width="200px" height="60px" style={{ "margin-top": "10px", "margin-right": "10px" }} alt="playstore" />
                </a>
                <a rel="ShopEG Appstore" href="https://apps.apple.com/in/app/shopeg/id1505233236"
                   target="_blank" rel="noreferrer" onClick={()=>{
                    firebaseAnalytics.logEvent( "SHOP_PAGE_CLICK_APP_STORE_ICON");
                }}>
                    <img src="/i/appstore.jpg" width="200px" height="60px" style={{ "margin-top": "10px" }} alt="Appstore" />
                </a>
            </BrowserView>


        </div>
    );
};

export default Footer;

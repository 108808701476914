import React from 'react';
import styled from 'styled-components';
import scanIcon from '../assets/icons/scan.svg';
import bagIcon from '../assets/icons/bag.svg';
import increaseIcon from '../assets/icons/money-increase.svg';
import commissionIcon from '../assets/icons/low-commission.svg';

const CardRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 10px auto;
  max-width: 1200px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(32, 32, 32, 0.1);
  padding: 20px;
  flex: 1;
  @media (max-width: 768px) {
    width: 90%;
  
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 80px;
  height: 80px;
  background-color: #E8E6FE;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
`;

const TextContainer = styled.div`
  margin: 0;
  text-align: center;
  color: #0a033c;
  font-size: 32px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 22px;
  font-weight: bold;
  }
`;

const CardRow = () => {
  return (
    <div>
    <CardRowContainer>
      <Card>
        <Content>
          <IconContainer>
            <Icon src={scanIcon} alt="Icon" />
          </IconContainer>
          <TextContainer>
            <p>कैमरे से स्कैन करें</p>
          </TextContainer>
        </Content>
      </Card>
      <Card>
        <Content>
          <IconContainer style={{backgroundColor:"#FFE6AE"}}>
            <Icon src={bagIcon} alt="Icon" />
          </IconContainer>
          <TextContainer>
            <p>ऑर्डर प्राप्त करें</p>
          </TextContainer>
        </Content>
      </Card>
    </CardRowContainer>
    <CardRowContainer>
      <Card>
        <Content>
          <IconContainer style={{backgroundColor:"#FFFCC5"}}>
            <Icon src={commissionIcon} alt="Icon" />
          </IconContainer>
          <TextContainer>
            <p>कम कमीशन</p>
          </TextContainer>
        </Content>
      </Card>
      <Card>
        <Content>
          <IconContainer style={{backgroundColor:"#D6FFE7"}}>
            <Icon src={increaseIcon} alt="Icon" />
          </IconContainer>
          <TextContainer>
            <p>अधिक बिक्री</p>
          </TextContainer>
        </Content>
      </Card>
    </CardRowContainer>
    </div>
  );
};

export default CardRow;

import React from 'react';
import '../layout/PricingSection.css';
import PricingCard from "../components/PricingCard";


const PricingSection = () => {


    const pricingPlans = [
        {
          title: 'Silver Plan',
          // subtitle:'Free',
           price: 'Free',
          features: [
            'Scan products using AI',
            'Sell on ONDC',
            'Basic Shop Website',
            'Low commission per order',
          ],
          buttonLabel: 'Get Started',
          isPrimary: false
        },
        {
          title: 'Gold Plan',
          subtitle:'',
          price: 'Monthly Recharge',
          features: [
           'Advanced features coming soon'
          ],
          buttonLabel: 'Coming soon',
          isPrimary: true
        }
        // Add more plans as needed
      ];
  

  return (
    <div className="pricing-section">
    {pricingPlans.map((plan, index) => (
      <PricingCard key={index} {...plan} />
    ))}
    </div>
  );
};

export default PricingSection;

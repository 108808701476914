import React from 'react';
import '../layout/HowTo.css';
import scanIcon from '../assets/icons/howTo/scan.svg';
import onboardIcon from '../assets/icons/howTo/onboard.svg';
import activateIcon from '../assets/icons/howTo/activate-your-shop.svg';
import deliverIcon from '../assets/icons/howTo/deliver-orders.svg';


const HowTo= () => {


    const Step = ({ image, title, description }) => (
        <div className="step">
          <img src={image} alt={title} className="step-image" />
          <h3 className="step-title">{title}</h3>
          <br />
          <p className="step-description">{description}</p>
        </div>
      );
      
  

  return (
    <div className="how-it-works">
    <h1>ಇದು ಹೇಗೆ ಕೆಲಸ ಮಾಡುತ್ತದೆ</h1>
    <br/><br/><br/><br/><br/>
    <div className="steps-container">
      <Step image={onboardIcon} title="ಅಂಗಡಿ ರಚಿಸಿ" description="Playstore ನಿಂದ ಶಾಪ್ ಈಜಿ ಅಪ್ಲಿಕೇಶನ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ ಮತ್ತು ನಿಮ್ಮ ಅಂಗಡಿಯನ್ನು ಡೆಲಿವರಿ ರೇಡಿಯಸ್‌ನೊಂದಿಗೆ ರಚಿಸಿ" />
      <svg class="curvy-line" width="180" height="100px" data-aos="fade-right"
         data-aos-delay="80"
         data-aos-duration="12000"> {5}
    <path d="M0,50 L230,50" stroke="#898A8D" stroke-width="1" fill="none" stroke-dasharray="5,5" />
</svg>
      <Step image={scanIcon} title="ಉತ್ಪನ್ನಗಳನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ" description="ನಮ್ಮ ಕ್ಯಾಮರಾ AI (ಈಜಿ ಸ್ಕ್ಯಾನ್) ಬಳಸಿಕೊಂಡು ಶೆಲ್ಫ್‌ನಿಂದ ಉತ್ಪನ್ನಗಳನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ ಮತ್ತು 15-30 ನಿಮಿಷಗಳಲ್ಲಿ ನಿಮ್ಮ ಕ್ಯಾಟಲಾಗ್ ಅನ್ನು ರಚಿಸಿ" />
      <svg class="curvy-line" width="180" height="100px"data-aos="fade-right"
         data-aos-delay="50"
         data-aos-duration="8000"> {5}
    <path d="M0,50 L230,50" stroke="#898A8D" stroke-width="1" fill="none" stroke-dasharray="5,5" />
</svg>
      <Step image={activateIcon} title="ಅಂಗಡಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ" description="KYC ಅನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಅಂಗಡಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ" />
      <svg class="curvy-line" width="180" height="100px"data-aos="fade-right"
         data-aos-delay="20"
         data-aos-duration="5000"> {5}
    <path d="M0,50 L230,50" stroke="#898A8D" stroke-width="1" fill="none" stroke-dasharray="5,5" />
</svg>

      <Step image={deliverIcon} title="ಆರ್ಡರ್‌ಗಳು ಮತ್ತು ಡೆಲಿವರಿ" description="ಹತ್ತಿರದ ಗ್ರಾಹಕರಿಂದ ಆದೇಶಗಳನ್ನು ಸ್ವೀಕರಿಸಿ ಮತ್ತು ಅವುಗಳನ್ನು ತಲುಪಿಸಿ" />
    </div>
    </div>
  );
};

export default HowTo;

import React from 'react';
import logo from '../../../assets/offers/logo.png'
import {MobileView} from "react-device-detect";
import {useHistory, withRouter} from 'react-router'
import { firebaseAnalytics } from '../../../utils/firebase_config';

const Header = ({shopData}) => {

    let history = useHistory();

    return (
        <div className='header'>
                <img src={logo}/>
                <h3>{shopData.shop_name}</h3>
            <button style={{textDecoration:"none"}} onClick={()=>{ 
                window.scrollTo(0, document.body.scrollHeight);
           
                firebaseAnalytics.logEvent( "SHOP_PAGE_CLICK_ORDER_NOW_WEB");
            }}>
                <button className='downloadButton'>Order Now</button>
            </button>
        </div>
    );
};

export default Header;

import React from "react";
import { Link, Redirect, Route } from 'react-router-dom'
import {UserAgentProvider, UserAgent} from '@quentin-sommer/react-useragent'
import AOS from "aos";
import "aos/dist/aos.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { Helmet } from "react-helmet";



class Shop extends React.Component {


  constructor(props) {

    super(props);
    
    this.state = {
      shop: null,
      products: null,
      categories: null,
      isLoading: true
    }

   } 

  async componentDidMount() {
   
    const doc = await fetch('https://prod-api.shopeg.in/api/client/shop/'+ window.location.pathname.split('/')[2])
  
    const productsResponse = await fetch('https://prod-api.shopeg.in/api/client/inventory/shopHighlights/'+ window.location.pathname.split('/')[2])

    const data = (await productsResponse.json()).result.data;
    const productsRaw = data.products;
    const categoriesRaw = data.categories;
    const shop = (await doc.json()).result.data;

   // console.log(products);
    
    AOS.init({
      duration : 1000
    })

    var categories = [];
    var products = [];
  
    productsRaw.forEach(product => {
      if(product.deletedAt == "" || product.deletedAt == null) {
        products.push(product);
      }
    });

    categoriesRaw.forEach(category => {
      if(category.deletedAt == "" || category.deletedAt == null) {
        categories.push(category);
      }
    })

    products.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1)

    this.setState({
      products: products,
      categories: categories,
      shop: shop,
      isLoading: false
      }); 
  }


  render() {
    
    if (this.state.isLoading == true) {
      return (
        <div
            style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
           }}>
        <Loader
          type="Bars"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={3000} //3 secs
          />
      </div> 
      );
    }
  
    if(this.state.shop == null) {
      return (
                    
       <body>
       <div className="error-content">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ">
                    <div className="error-text">
                        <h1 className="error">404</h1>
                        <div className="im-sheep">
                            <div className="top">
                                <div className="body"></div>
                                <div className="head">
                                    <div className="im-eye one"></div>
                                    <div className="im-eye two"></div>
                                    <div className="im-ear one"></div>
                                    <div className="im-ear two"></div>
                                </div>
                            </div>
                            <div className="im-legs">
                                <div className="im-leg"></div>
                                <div className="im-leg"></div>
                                <div className="im-leg"></div>
                                <div className="im-leg"></div>
                            </div>
                        </div>
                        <h4>Oops! This shop does not exist!</h4>
                        <p>Please download ShopEG to find shops near you :) </p>
                        <a href="https://play.google.com/store/apps/details?id=com.beyondseek.shopez.cust"><button type="button" className="btn btn-success rounded-pill "><h5 className="mr-3 ml-3">Play Store</h5></button></a>
                        <a href="https://apps.apple.com/in/app/shopeg/id1505233236"><button type="button" className="btn btn-success rounded-pill "><h5 className="mr-3 ml-3">App Store</h5></button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
       </body>
 
      )
    }

    console.log('state');
    console.log(this.state.shop.shop_name);
    console.log(this.state.shop.link);
    console.log(this.state.shop.shop_image);
return ( 
  <div>

<div className="bodydiv">
<Helmet>
        <title>{this.state.shop.shop_name}</title>
            <meta id="meta-description" name="description" content={"Order online from " + this.state.shop.shop_name +  ". " + (this.state.shop.delivery == true ? "Get FREE delivery today!" : "")}/>
            <meta id="og-title" property="og:title" content={this.state.shop.shop_name} />
            <meta id="og-image" property="og:image" content={this.state.shop.link} />
  </Helmet>

  
  <div className="bottom-bar" >
  <nav className ="navbar navbar-fixed-bottom navbar-inverse">
    <div className="container" style={{ 
    justifyContent: "center",
    alignItems: "center",}}>
        <div className="elements">

          <BrowserView >
          <h3 className="instructions" style={{marginLeft:100}}>Download ShopEG for Android or iOS for the best experience!</h3>
          <a className="col-md-4 "> </a>
          <a className=" col-md-3 mr-10 ml-10 " href="https://play.google.com/store/apps/details?id=com.beyondseek.shopez.cust" ><button type="button" className="btn btn-success rounded-pill"  ><h5 className="mr-2 ml-2">Play Store</h5></button></a>
         {/* className=" col-md-3 mr-10 ml-10 " */}
          <a className=" col-md-3 mr-10 ml-10 " href="https://apps.apple.com/in/app/shopeg/id1505233236"><button type="button" className="btn btn-success rounded-pill "><h5 className="mr-2 ml-2">App Store</h5></button></a>
         
          </BrowserView>

          <MobileView>
          {/* <h3 className="instructions">&nbsp;</h3> */}
<br/>
<br/>
<br/>
         <a className="col-6"> <br/> &nbsp;</a><a className="col-6"> &nbsp;</a> <a className="col-6 " href={this.state.shop.link}><button type="button" className="btn btn-success rounded-pill "><h5 className="mr-3 ml-3">View in App</h5></button></a>
          </MobileView>
       
        </div>
        <div className="navbar-header">
       
        </div>
        {/* <div className="navbar-header">
        
        
          <button type="button" className="btn btn-success rounded-pill "><h5 className="mr-3 ml-3">Place Order</h5></button>
        </div> */}
    </div>
  </nav>
  </div>
  <nav className ="navbar-fluid hidden-navbar fixed-top"> 
                 <img className="nav-image" src="/i/shed.png" style={{color: 'rgba(69,39,160,0.8)' ,}} />
            </nav>
  <section id="hero" className="d-flex align-items-center justify-content-center" style={{backgroundImage: "url(" + (this.state.shop.shop_image != null ? this.state.shop.shop_image : "https://firebasestorage.googleapis.com/v0/b/shopez-cust.appspot.com/o/stock%2Fgroceries.jpeg?alt=media&token=49ce7aa8-e3ed-4760-8e31-ede84a5f26e6") +")"}}>


    <div className="container" data-aos="fade-down">

      <div className="row justify-content-center" data-aos="fade-down" data-aos-delay="50">
        <div className="col-xl-6 col-lg-8">
          <h3><span>{this.state.shop.shop_name}</span></h3>
          {/* <h5>We are team of talented digital marketers We are team of talented digital marketers We are team of talented digital marketers</h5> */}
        </div>
      </div>

      <div className="row mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="150">
       {
   
   this.state.shop.delivery == true && 
          <div className="col-xl-2 col-md-4 col-6">
          <div className="icon-box rounded">
            <i className="fas fa-truck" ></i>
            <h4><p >Free Delivery <br/> {this.state.shop.delivery.orderLimit != null && <p> Minimum Order Limit {this.state.shop.delivery.orderLimit} </p>}</p></h4>
          </div>
           </div>
       }

        <div className="col-xl-2 col-md-4 col-6 ">
          <div className="icon-box rounded">
            <i className="fas fa-shopping-bag"></i>
            <h4><p>Store pick Up</p></h4>
          </div>
        </div>

        <div className="col-xl-2 col-md-4 col-6 mt-4 mt-md-0">
          <div className="icon-box rounded">
            <i className="fas fa-comment"></i>
            <h4><p> Chat with us</p></h4>
          </div>
        </div>

        <div className="col-xl-2 col-md-4 col-6 mt-4 mt-xl-0">
          <div className="icon-box rounded">
            <i className="fas fa-users"></i>
            <h4><p>100% Customer Satisfaction</p></h4>
          </div>
        </div>
      </div>

    </div>
    
  </section>

  {
  this.state.shop.desc != null &&
  <div className="headerName container-fiuld " data-aos="fade-down" data-aos-delay="350">
      <h3 className="font-bold " style={{color : "#4527a0", backgroundColor: "#DCEDC8",lineHeight: "2",textAlign: "center"}}>{this.state.shop.desc}</h3>
      </div>  
  }
    <div className="headerName container " data-aos="fade-down" data-aos-delay="350">
      <h1 className=" font-bold " style={{ color : "#4527a0"}} >Categories</h1>
      <div className="row">


    {this.state.categories.map((category) => (
      
      <div className="chip">
        <div className="chip-content">{category.categoryName}</div>
      </div>
        ))}

  </div>

    </div>
 

<br/>
<div >
  <div className="container shadow rounded" >
    <h1 className="font-bold text-3xl " style={{ color : "#4527a0"}}>Highlights</h1>
    <br/>
    <div className="row" data-aos="zoom-in" data-aos-delay="550">
    {this.state.products.map((product) => (
      <div className="col-xs-12 col-sm-6">
        <div className={product.imgUrl !== null ? "card horizontal" : "card"}>
        
          {product.imgUrl !== null ? <img className="card-img-top rounded" src={product.imgUrl} alt={product.name}/> : <img></img>}
          <div className="card-body">
            <h4 >{product.name}</h4>
          </div>
          <div className="card-footer">
            <div className="read__more"><h5 > {product.quantity} {product.quantityUnit}</h5></div>
            <div className="read__more">{ (product.price !== "" && product.mrp > product.price && parseFloat(product.price) !== 0) ? <p>MRP: Rs <del>{product.mrp}</del></p> : (product.mrp != null && parseFloat(product.mrp) != 0 ?  "MRP: Rs " + product.mrp  : "") }</div>
            <div className="read__more"><h4> {(product.price !== product.mrp && product.price !== "" && parseFloat(product.price) !== 0 )? "Price: Rs " + product.price : ""}</h4></div>
          </div>
        </div>
      </div>
    ))}
      </div>

  </div>
</div>

<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

  <a href="#" className="back-to-top"><i className="fas fa-angle-up"></i></a>
  <script src="/__/firebase/8.2.8/firebase-app.js"></script>
  <script src="/__/firebase/8.2.8/firebase-auth.js"></script>
  <script src="/__/firebase/8.2.8/firebase-firestore.js"></script>
  <script src="/__/firebase/init.js"></script>

  <script src="/assets/vendor/jquery/jquery.min.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/jquery.easing/jquery.easing.min.js"></script>

  <script src="/assets/vendor/owl.carousel/owl.carousel.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/venobox/venobox.min.js"></script>
  <script src="/assets/vendor/waypoints/jquery.waypoints.min.js"></script>
  <script src="/assets/vendor/counterup/counterup.min.js"></script>
  <script src="/assets/vendor/aos/aos.js"></script>


  <script src="/assets/js/main.js"></script>
  </div>
</div>
    )
  }
  
}

 export default Shop;
  
  // export async function getStaticPaths() {
  
  //   const paths = []
  
  //   return { paths, fallback: false }
  // }
  // export async function getStaticProps() {
  //   const router = useRouter();

  //   const doc = await fetch('http://localhost:1339/api/client/shop/'+ router.query.shopId)

  //   const productsResponse = await fetch('http://localhost:1339/api/client/inventory/shopHighlights/'+ router.query.shopId)
  // //  const categoriesResponse = await fetch('http://localhost:1339/api/client/inventory/shop/category/'+ query.shopId)
  // //  const productsResponse = await fetch('http://shopegdevebsmumbai-env-1.ap-south-1.elasticbeanstalk.com/api/client/inventory/shop/'+ query.shopId)
  // //  const categoriesResponse = await fetch('http://shopegdevebsmumbai-env-1.ap-south-1.elasticbeanstalk.com/api/client/inventory/shop/category/'+ query.shopId)

  //   const data = (await productsResponse.json()).result.data;
  //   const products = data.products;
  //   const categories = data.categories;
  //   const shop = (await doc.json()).result.data;

  //   console.log(products);
  //   products.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1)

  //   return {
  //       props: {
  //         products: products,
  //       categories: categories,
  //       shop: shop
  //       }
  //   }
  // }

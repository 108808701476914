import React from 'react';
import '../layout/PricingSection.css';
import PricingCard from "../components/PricingCard";


const PricingSection = () => {


    const pricingPlans = [
        {
          title: 'सिलवर प्लान',
          // subtitle:'Free',
           price: 'मुफ़्त',
          features: [
            'AI का उपयोग करके उत्पादों को स्कैन करें',
            'ओएनडीसी पर बेचें',
            'बेसिक शॉप वेबसाइट',
            'प्रति ऑर्डर कम कमीशन',
          ],
          buttonLabel: 'शुरू हो जाओ',
          isPrimary: false
        },
        {
          title: 'गोल्ड प्लान',
          subtitle:'',
          price: 'मासिक रिचार्ज',
          features: [
           'उन्नत सुविधाएँ जल्द ही आ रही हैं'
          ],
          buttonLabel: 'Coming soon',
          isPrimary: true
        }
        // Add more plans as needed
      ];
  

  return (
    <div className="pricing-section">
    {pricingPlans.map((plan, index) => (
      <PricingCard key={index} {...plan} />
    ))}
    </div>
  );
};

export default PricingSection;

import React from "react";
// import AOS from "aos";
import "aos/dist/aos.css";
import CardRowHin from "../layout/cardRowHin";
import MenuHin from "./MenuHin";
import HeroSectionHin from "../layout/heroHin";
import styled from "styled-components";
import FeaturesSectionHin from "../layout/featuresSectionHin";
import PricingSectionHin from "../layout/PricingSectionHin";
import scanIcon from "../assets/icons/scan.svg";
import bagIcon from "../assets/icons/bag.svg";
import increaseIcon from "../assets/icons/money-increase.svg";
import commissionIcon from "../assets/icons/low-commission.svg";
import HowToHin from "../layout/HowToHin";
import ReviewSectionHin from "../layout/reviewSectionHin";

const StyledCol = styled.div`
  display: flex;
  justify-content: center;
  height: 350px;
  width: 320px;
  @media (max-width: 768px) {
    height: 300px;
    width: 90%;
  }
`;

const StyledImage = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 10px;
`;

const labelStyle = {
  position: "absolute",
  top: "20px",
  left: "20px",
  backgroundColor: "#FFFFFF", // Label background color
  color: "#4CAF51", // Text color
  fontSize: "22px", // Adjusted font size
  fontWeight: 600, // Adjusted font weight
  padding: "12px 20px",
  borderRadius: "10px",
  boxShadow: "0px 4px 8px rgba(32, 32, 32, 0.1)",
};

const homePage = ``;

class HomeHin extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    //   return (
    //     <div className="content" dangerouslySetInnerHTML={{__html: homePage}}></div>
    // );
    // return (
    //   <Redirect to="i" />
    // )

    const featuresData = [
      {
        id: 1,
        title: "कैमरे का उपयोग करके उत्पादों को स्कैन करें",
        description:
          "हमारे कैमरा आधारित AI का उपयोग करके अपने स्टोर पर उत्पादों को स्कैन करें और तुरंत ऑनलाइन कैटलॉग बनाएं",
        image: scanIcon,
      },
      {
        id: 2,
        title: "ऑर्डर प्राप्त करें",
        description:
          "बिना किसी मार्केटिंग प्रयास के ऑर्डर प्राप्त करें। ShopEG पर आसानी से ऑर्डर प्रबंधित करें",
        image: bagIcon,
      },
      {
        id: 3,
        title: "कम कमीशन",
        description:
          "शॉप ईजी पर न्यूनतम शुल्क/शुल्क के साथ प्रत्येक ऑर्डर पर अधिकतम कमाई",
        image: commissionIcon,
      },
      {
        id: 4,
        title: "अधिक बिक्री",
        description:
          "ONDC के माध्यम से शॉप ईजी के साथ बढ़ी हुई बिक्री क्षमता को अनलॉक करें, विभिन्न पिनकोड पर ग्राहकों को सेवा प्रदान करें।",
        image: increaseIcon,
      },
    ];

    return (
      <div className="home">
        <div>
          <MenuHin style={{ background: "#F7F7F7" }} />
          <br />
          <header className="pt-40 pb-60 header_1 ">
            <div className="container hero ">
              <div
                className="row align-items-center "
                data-aos="fade-down"
                data-aos-delay="10"
              >
                <HeroSectionHin
                  className="container"
                  data-aos="fade-down"
                  data-aos-delay="10"
                />

                {/* <div
                  className="col-md-6 "
                  data-aos="fade-down"
                  data-aos-delay="10"
                >
                  <h1
                    className="mt-60 f-42 text-left font-weight-bold"
                    data-aos="fade-down"
                    data-aos-delay="10"
                  >
                    Take your Shop online using ShopEG AI within seconds.
                  </h1>

                  <div
                    className="mw-600 mx-auto mt-30 f-22 color-heading text-left text-adaptive"
                    data-aos="fade-down"
                    data-aos-delay="250"
                  >
                    Create catalog, Receive orders, Increase sales
                  </div>
                  <div
                    className="mw-600 mx-auto mt-30 f-16 color-black text-left text-adaptive"
                    data-aos="fade-down"
                    data-aos-delay="250"
                  >
                    Trusted and used by 100s of shopkeepers and their customers
                    across Bengaluru!
                  </div>

                  <br />
                  <br />

                  <div
                    className="page-content page-container"
                    id="page-content"
                    data-aos="fade-down"
                    data-aos-delay="0"
                  >
                    <div className="padding">
                      <div className="row container d-md-flex justify-left ">
                        <div className=" mt-2">
                          <a
                            rel="ShopEG Playstore"
                            href="https://play.google.com/store/apps/details?id=com.beyondseek.shopez.cust&referrer=utm_source%3Dshopeg.in"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/i/playstore.jpg"
                              width="200px"
                              height="60px"
                              style={{
                                "margin-top": "10px",
                                "margin-right": "10px",
                              }}
                              alt="playstore"
                            />
                          </a>
                          <a
                            rel="ShopEG Appstore"
                            href="https://apps.apple.com/in/app/shopeg/id1505233236"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/i/appstore.jpg"
                              width="200px"
                              height="60px"
                              style={{ "margin-top": "10px" }}
                              alt="Appstore"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              

                <div
                  className="col-md-6"
                  data-aos="fade-down "
                  data-aos-delay="100"
                >
                  <div
                    className="center"
                    style={{ "margin-right": "20% !important" }}
                  >
                    <Rive
                      className="rive-anim"
                      src="/assets/animation/scan_animation.riv"
                      autoplay
                      alt="Scanning_Shelf  "
                    />
                  </div>

                </div> */}
              </div>
            </div>
          </header>
          <section data-aos="fade-down " data-aos-delay="100">
            <FeaturesSectionHin
              features={featuresData}
              appImageSrc={"/i/screenshots-1.svg"}
              overlayColor="#00000080" // Example of a semi-transparent overlay color
            />
          </section>
          <section data-aos="fade-down " data-aos-delay="100">
            <HowToHin />
          </section>
          <section data-aos="fade-down " data-aos-delay="100">
            <ReviewSectionHin />
          </section>
          <section>
            <div>
              <h1
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                कीमत निर्धारण
              </h1>
              ;
              <PricingSectionHin />
            </div>
          </section>
          <section
            className="pt-20  container"
            // style={{
            //   "border-radius": "4em",
            //   background: "linear-gradient(83deg, #4527A0,#8862F1, #4527A0 )",

            // }}
          >
            <br />
            <div
              className="call_to_action_1"
              style={{
                "border-radius": "2em",
                background: "linear-gradient(83deg, #4527A0,#8862F1, #4527A0 )",
              }}
            >
              <div className="container px-xl-0">
                <div className="row justify-content-center">
                  {/* <div className="col-xl-1  col-lg-1 col-md-1"></div> */}

                  <div className="col-xl-5 col-lg-5 col-md-6 color-white ">
                    {/* <div style={labelStyle}>शॉप ईजी</div>
                    <br /> */}
                    <h1
                      data-aos="fade-down"
                      data-aos-delay="0"
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontSize: "42px",
                      }}
                    >
                      अपनी दुकान को ऑनलाइन बड़ा करें। अभी स्कैन करना प्रारंभ
                      करें!
                    </h1>
                    {/* <div
                      className="mt-20 mb-60 mr-30 f-22 color-white text-adaptive description"
                      data-aos="fade-down"
                      data-aos-delay="250"
                    >
                      Get your order delivered from the store or pick it up when
                      its ready!
                    </div> */}
                    <div data-aos="fade-down" data-aos-delay="500">
                      <a
                        href="https://api.whatsapp.com/send?phone=918618563552&text=%E0%A4%A8%E0%A4%AE%E0%A4%B8%E0%A5%8D%E0%A4%A4%E0%A5%87%2C%20%E0%A4%AE%E0%A5%88%E0%A4%82%20%E0%A4%B6%E0%A5%89%E0%A4%AA%E0%A4%88%E0%A4%9C%E0%A5%80%20%E0%A4%B8%E0%A5%87%20%E0%A4%9C%E0%A5%81%E0%A4%A1%E0%A4%BC%E0%A4%A8%E0%A4%BE%20%E0%A4%9A%E0%A4%BE%E0%A4%B9%E0%A4%A4%E0%A4%BE%20%E0%A4%B9%E0%A5%82%E0%A4%82%E0%A5%A4"
                        style={{
                          display: "inline-block",
                          borderRadius: "10px",
                          backgroundColor: "#4CAF50",
                          color: "white",
                          padding: "15px",
                          fontSize: "22px",
                          textDecoration: "none", // Remove underline style
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        शॉपईजी से जुड़ें
                      </a>
                    </div>
                  </div>
                  <StyledCol className="col-xl-6 col-lg-6">
                    <StyledImage src="i/shop.png" alt="Order from home" />
                  </StyledCol>
                </div>
              </div>
            </div>
          </section>
          <br /> <br />
          {/* 
          <section>
            <div className="socials container ">
              <div className="row">
                <div className="col-3"></div>
                <div className="col col-sm-6 col-md-6">
                  <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="ShopEG_IN"
                    options={{ height: 500 }}

                    // onComplete={action}
                  />
                </div>

                <div className="col-2"> */}
          {/* <InstagramEmbed
  url='https://www.instagram.com/shopeg.in/'
  clientAccessToken='IGQVJVZA3lJenJaQS1OV3pEWk96VnJrdlNGM0ZAZASjd0TGxic3NhSExGT0hRYmExUTYtWHNRcjdmTURvM3lTNmNIUzVFbXpfQktleVUwOFZA6Yi1JaDlLUEdQVHhKMzJoMmNvSzZAHbGNfMURxbkpaZAUtQWAZDZD' 
  maxWidth={320}
  hideCaption={false}
  containerTagName='div'
  protocol=''
  injectScript
  onLoading={() => {}}
  onSuccess={() => {}}
  onAfterRender={() => {}}
  onFailure={() => {}}
/> */}
          {/* </div>
              </div>
            </div>
          </section> */}
          <footer
            className="pt-70 pb-20  text-center footer_1 "
            style={{
              background: "linear-gradient(120deg, #4527A0,#8862F1, #4527A0 )",
            }}
          >
            <div className="container px-xl-0">
              <div className="row justify-content-between align-items-center lh-40 links">
                <div
                  className="col-lg-6 col-sm-6 text-sm-right text-lg-left order-1 order-lg-0"
                  data-aos="fade-down"
                  data-aos-delay="250"
                >
                  <a
                    href="https://wa.me/message/XUJYONCJB4VKE1"
                    alt="Contact Sheet"
                    className="link mx-15 color-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    संपर्क करें
                  </a>
                  <a href="/privacy.html" className="link mx-15 color-white">
                    गोपनीयता नीति
                  </a>
                  <a
                    href="/seller-agreement.html"
                    className="link mx-15 color-white"
                  >
                    शर्तें और समझौता
                  </a>
                </div>
                <div
                  className="col-lg-4 col-sm-6 text-sm-left text-lg-right order-2 order-lg-0"
                  data-aos="fade-down"
                  data-aos-delay="250"
                >
                  <a
                    href="https://wa.me/message/XUJYONCJB4VKE1"
                    className="link mr-15 color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="WhatsApp"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                  <a
                    href="https://twitter.com/ShopEG_IN"
                    className="mx-15 link color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Twitter"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/ShopEGapp/"
                    className="mx-15 link color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Facebook"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/shopeg.in/"
                    className="ml-15 link color-white"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <i className="fab fa-instagram "></i>
                  </a>
                </div>
              </div>
              <div
                className="mb-10 mb-lg-0 col-lg-auto order-0"
                data-aos="fade-down"
                data-aos-delay="0"
              >
                <a className="link logo color-main" href="/">
                  <div className="row">
                    {" "}
                    <img
                      src="/i/eg.png"
                      height="32"
                      width="30"
                      alt="ShopEG logo"
                    />
                    <p className="color-white" style={{ marginLeft: "4px" }}>
                      {" "}
                      शॉप ईजी
                    </p>
                  </div>
                </a>
              </div>
              <div className="row justify-content-center color-white">
                <div
                  className="mt-10 col-xl-4 col-lg-5 col-md-6 col-sm-8"
                  data-aos="fade-down"
                  data-aos-delay="0"
                >
                  <div className="color-white text-adaptive">
                    © कॉपीराइ स्पार्क इटरनल टेक्नोलॉजीस प्राइवेट लिमिटेड। सभी
                    अधिकार सुरक्षित
                    <span>
                      <p></p>
                      <p>
                        {" "}
                        No. 65, 83rd Cross, 1st Stage Kumaraswamy Layout,
                        Bangalore, Karnataka, 560078
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default HomeHin;

import React from 'react';
import '../layout/PricingSection.css';
import PricingCard from "../components/PricingCard";


const PricingSection = () => {


    const pricingPlans = [
        {
          title: 'ಸಿಲ್ವರ್ ಪ್ಲ್ಯಾನ್',
          // subtitle:'Free',
           price: 'ಉಚಿತ',
          features: [
            'AI ಬಳಸಿಕೊಂಡು ಉತ್ಪನ್ನಗಳನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ',
            'ONDC ನಲ್ಲಿ ಮಾರಾಟ ಮಾಡಿ',
            'ಮೂಲ ಅಂಗಡಿ ವೆಬ್‌ಸೈಟ್',
            'ಪ್ರತಿ ಆದೇಶಕ್ಕೆ ಕಡಿಮೆ ಕಮಿಷನ್',
          ],
          buttonLabel: 'ಪ್ರಾರಂಭಿಸಿ',
          isPrimary: false
        },
        {
          title: 'ಗೋಲ್ಡ್ ಪ್ಲ್ಯಾನ್',
          subtitle:'',
          price: 'ಮಾಸಿಕ ರೀಚಾರ್ಜ್',
          features: [
           'ಸುಧಾರಿತ ವೈಶಿಷ್ಟ್ಯಗಳು ಶೀಘ್ರದಲ್ಲೇ ಬರಲಿವೆ'
          ],
          buttonLabel: 'ಶೀಘ್ರದಲ್ಲೇ ಬರಲಿದೆ',
          isPrimary: true
        }
        // Add more plans as needed
      ];
  

  return (
    <div className="pricing-section">
    {pricingPlans.map((plan, index) => (
      <PricingCard key={index} {...plan} />
    ))}
    </div>
  );
};

export default PricingSection;

import React from 'react';
import '../layout/HowTo.css';
import scanIcon from '../assets/icons/howTo/scan.svg';
import onboardIcon from '../assets/icons/howTo/onboard.svg';
import activateIcon from '../assets/icons/howTo/activate-your-shop.svg';
import deliverIcon from '../assets/icons/howTo/deliver-orders.svg';


const HowTo= () => {


    const Step = ({ image, title, description }) => (
        <div className="step">
          <img src={image} alt={title} className="step-image" />
          <h3 className="step-title">{title}</h3>
          <br />
          <p className="step-description">{description}</p>
        </div>
      );
      
  

  return (
    <div className="how-it-works">
    <h1>यह किस प्रकार काम करता है</h1>
    <br/><br/><br/><br/><br/>
    <div className="steps-container">
      <Step image={onboardIcon} title="दुकान बनाएं" description="Play Store से शॉप ईजी ऐप डाउनलोड करें और डिलीवरी रेडियस के साथ अपनी दुकान बनाएं" />
      <svg class="curvy-line" width="180" height="100px" data-aos="fade-right"
         data-aos-delay="80"
         data-aos-duration="12000"> {5}
    <path d="M0,50 L230,50" stroke="#898A8D" stroke-width="1" fill="none" stroke-dasharray="5,5" />
</svg>
      <Step image={scanIcon} title="उत्पाद स्कैन करें" description="हमारे कैमरा AI (ईजी स्कैन) का उपयोग करके शेल्फ से उत्पादों को स्कैन करें और 15-30 मिनट में अपना कैटलॉग बनाएं" />
      <svg class="curvy-line" width="180" height="100px"data-aos="fade-right"
         data-aos-delay="50"
         data-aos-duration="8000"> {5}
    <path d="M0,50 L230,50" stroke="#898A8D" stroke-width="1" fill="none" stroke-dasharray="5,5" />
</svg>
      <Step image={activateIcon} title="दुकान सक्रिय करें" description="केवाईसी पूरा करें और अपना स्टोर सक्रिय करें" />
      <svg class="curvy-line" width="180" height="100px"data-aos="fade-right"
         data-aos-delay="20"
         data-aos-duration="5000"> {5}
    <path d="M0,50 L230,50" stroke="#898A8D" stroke-width="1" fill="none" stroke-dasharray="5,5" />
</svg>

      <Step image={deliverIcon} title="ऑर्डर और डिलिवरी" description="आस-पास के ग्राहकों से ऑर्डर प्राप्त करें और उन्हें वितरित करें" />
    </div>
    </div>
  );
};

export default HowTo;

import React from "react";
import Rive from "rive-react";
import styled from "styled-components";
import "../layout/hero.css";

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 20px;
  margin-top: ${(props) => (props.mobileMarginTop ? "0" : "70px")};
  @media (max-width: 738px) {
    margin-top: ${(props) => (props.mobileMarginTop ? "0" : "40px")};
  }
`;

const Label = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #ffffff;
  color: #4caf51;
  font-size: 22px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.1);
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: bold;
  }
`;

const HeroContent = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const HeroText = styled.h1`
  font-size: 52px;
  font-weight: bold;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 38px;
  }
`;

const SubText = styled.p`
  font-size: 24px;
  color: #888;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Button = styled.button`
  background-color: #4caf51;
  color: white;
  font-size: 24px;
  border-radius: 10px;
  padding: 20px 40px;
  box-shadow: 0px 4px 8px rgba(76, 175, 81, 0.2);
  cursor: pointer;
  border: none;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const RiveAnimation = styled(Rive)`
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
`;

const HeroSectionHin = () => {

    const handleJoinButtonClick = () => {
      window.open("https://api.whatsapp.com/send?phone=918618563552&text=%E0%A4%A8%E0%A4%AE%E0%A4%B8%E0%A5%8D%E0%A4%A4%E0%A5%87%2C%20%E0%A4%AE%E0%A5%88%E0%A4%82%20%E0%A4%B6%E0%A5%89%E0%A4%AA%E0%A4%88%E0%A4%9C%E0%A5%80%20%E0%A4%B8%E0%A5%87%20%E0%A4%9C%E0%A5%81%E0%A4%A1%E0%A4%BC%E0%A4%A8%E0%A4%BE%20%E0%A4%9A%E0%A4%BE%E0%A4%B9%E0%A4%A4%E0%A4%BE%20%E0%A4%B9%E0%A5%82%E0%A4%82%E0%A5%A4", "_blank");
    };

  return (
    <HeroContainer>
      <Label>ओएनडीसी पर बेचें</Label>
      <HeroContent>
        <HeroText>अपनी ऑनलाइन दुकान का दरवाजा खोलें</HeroText>
        <SubText>
        हर सप्ताह केवल 15 मिनट बिताएं और अपने स्थानीय दुकान के लिए ऑर्डर प्राप्त करें
        </SubText>
        <Button onClick={handleJoinButtonClick}>शॉपईजी से जुड़ें</Button>
        <RiveAnimation
          className="rive-anim"
          src="/assets/animation/scan_animation.riv"
          autoplay
          alt="Scanning_Shelf"
        />
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSectionHin;

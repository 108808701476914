import React from "react";
import "../ShopLandingPage.css";

export default function InfoCard({ shopData }) {
  return (
    <div className="wrapper">
      {shopData.delivery === true && (
        <div className="infoWrapper">
          <i className="fas fa-truck infoIcon"></i>
          <h4>
            <p>
              Free Delivery <br />{" "}
              {shopData.delivery.orderLimit != null && (
                <p> Minimum Order Limit {shopData.delivery.orderLimit} </p>
              )}
            </p>
          </h4>
        </div>
      )}{" "}
      <div className="infoWrapper">
        <i className="fas fa-shopping-bag infoIcon"></i>
        <h4>
          <p>Store pick Up</p>
        </h4>
      </div>
      <div className="infoWrapper">
        <i className="fas fa-comment infoIcon"></i>
        <h4>
          <p> Chat with us</p>
        </h4>
      </div>
      <div className="infoWrapper">
        <i className="fas fa-users infoIcon"></i>
        <h4>
          <p>100% Customer Satisfaction</p>
        </h4>
      </div>
    </div>
  );
}

// AboutUs.js

import React from "react";
import "./AboutUs.css"; // Ensure this CSS file is in the same folder as your component
import shopImage from "../assets/shop3.svg";
import Menu from "./MenuAboutUs";
import basanthImage from "../assets/images/team/basanth.png";
import ranjanaImage from "../assets/images/team/ranjana.png";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const teamMembers = [
    {
      name: "Basanth",
      title: "Co-Founder",
      imageUrl: basanthImage,
      linkedin: "https://www.linkedin.com/in/basanthnverma/",
    },
    {
      name: "Ranjana",
      title: "Co-Founder",
      imageUrl: ranjanaImage,
      linkedin: "https://www.linkedin.com/in/ranjana-verma/",
    },

    // { name: 'Vijai Chandra Prasad', title: 'Founding Engineer', imageUrl: 'https://m.media-amazon.com/images/I/71VosPIHljL._SX679_.jpg' },
  ];

  return (
    <div>
      <Menu />
      <br />
      <br />
      <div className="about-container">
        <div className="our-story">
          <h1>About Us</h1>
          <p>
            ShopEG is a kirana aggregator platform with the ambitious goal of
            digitizing over 6 million small kirana stores across India. We aim
            to onboard these stores to the Open Network for Digital Commerce
            (ONDC), an initiative that aligns perfectly with our mission of
            digitizing India's retail landscape.
            <br />
            <br />
            Our unique selling proposition lies in our AI-based catalog
            digitisation system. This technology enables us to onboard a store
            with a catalog of over 1000 items in just 20-30 minutes,
            significantly reducing the time and effort required for store owners
            to transition to a digital platform.
            <br />
            <br />
            We believe that our solution can contribute significantly with the
            ONDC initiative by accelerating the digitization of small retail
            stores, a sector that forms the backbone of India's retail industry.
            By bringing these stores online, we can help them reach a wider
            customer base, improve their operational efficiency, and compete
            effectively in the digital economy.
          </p>
          <h1>Our Mission</h1>
          <p>
            We're here to become the leading enabler of growth and prosperity
            for small grocery stores across India, driving digital
            transformation and connecting them to customers in their
            neighbourhood and beyond. We are on a mission to transform the way
            small grocery stores in India operate and engage with their
            customers. Our journey will see us continually innovating and
            expanding our suite of digital solutions, while fostering an
            ever-growing network of thriving, digitally-enabled grocery stores
            across the country. As we move forward, we aim to create a lasting,
            positive impact on the lives of local store owners, their
            communities, and the Indian economy as a whole.
          </p>
        </div>
        {/* <div className="our-story">
          <h1>Why We Are Here</h1>
          <p>
            We're here to become the leading enabler of growth and prosperity
            for small grocery stores across India, driving digital
            transformation and connecting them to customers in their
            neighbourhood and beyond.
          </p>
          <p></p>
        </div> */}
        <div className="connecting-kiranas">
          <img src={shopImage} alt="Kirana store owner" />
          <h1>Digitise, Organise, & Expand the potential of Kirana stores</h1>
        </div>
        <div className="team-section">
          <h1>Meet Our Team</h1>
          <div className="team-members">
            {teamMembers.map((member) => (
              <div className="team-member" key={member.name}>
                <img src={member.imageUrl} alt={member.name} />
                <h3>{member.name}</h3>
                <p>{member.title}</p>
                <a
                  href={member.linkedin}
                  className="link color-black"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="LinkedIn"
                >
                  <i
                    class="fab fa-linkedin-in"
                    style={{ fontSize: "24px", width: "20px", height: "20px" }}
                  ></i>
                </a>
                {/* <i className="team-icon"></i> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <footer
        className="pt-70 pb-20  text-center footer_1 "
        style={{
          background: "linear-gradient(120deg, #4527A0,#8862F1, #4527A0 )",
        }}
      >
        <div className="container px-xl-0">
          <div className="row justify-content-between align-items-center lh-40 links">
            <div
              className="col-lg-6 col-sm-6 text-sm-right text-lg-left order-1 order-lg-0"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <a
                href="https://wa.me/message/XUJYONCJB4VKE1"
                alt="Contact Sheet"
                className="link mx-15 color-white"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
              <a href="/privacy.html" className="link mx-15 color-white">
                Privacy policy
              </a>
              <a
                href="/seller-agreement.html"
                className="link mx-15 color-white"
              >
                Terms and Agreement
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 text-sm-left text-lg-right order-2 order-lg-0"
              data-aos="fade-down"
              data-aos-delay="250"
            >
              <a
                href="https://wa.me/message/XUJYONCJB4VKE1"
                className="link mr-15 color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="WhatsApp"
              >
                <i className="fab fa-whatsapp"></i>
              </a>
              <a
                href="https://twitter.com/ShopEG_IN"
                className="mx-15 link color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.facebook.com/ShopEGapp/"
                className="mx-15 link color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
              <a
                href="https://www.instagram.com/shopeg.in/"
                className="ml-15 link color-white"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram "></i>
              </a>
            </div>
          </div>
          <div
            className="mb-10 mb-lg-0 col-lg-auto order-0"
            data-aos="fade-down"
            data-aos-delay="0"
          >
            <a className="link logo color-main" href="/">
              <div className="row">
                {" "}
                <img src="/i/eg.png" height="32" width="30" alt="ShopEG logo" />
                <p className="color-white" style={{ marginLeft: "4px" }}>
                  {" "}
                  ShopEG
                </p>
              </div>
            </a>
          </div>
          <div className="row justify-content-center color-white">
            <div
              className="mt-10 col-xl-4 col-lg-5 col-md-6 col-sm-8"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              <div className="color-white text-adaptive">
                © Copyright Sparketernal Technologies Pvt Ltd. All Rights
                Reserved
                <span>
                  <p></p>
                  <p>
                    {" "}
                    No. 65, 83rd Cross, 1st Stage Kumaraswamy Layout, Bangalore,
                    Karnataka, 560078
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutUs;

import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from "react-router-dom";
import {Home, ShopSlug,HinHome,KanHome,Shop, HomeHin, HomeKan,AboutUs, AboutUsKan, AboutUsHin } from "./pages";
import ShopLandingPage from "./pages/ShopLanding/ShopLandingPage";
import ShopLandingS from "./pages/ShopLanding/ShopLandingS";
import ShopLanding from "./pages/Shops/ShopLanding"


function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/index-hin" exact component={HomeHin} />
          <Route path="/aboutus" exact component={AboutUs} />
          <Route path="/AboutUs-Kan" exact component={AboutUsKan} />
          <Route path="/AboutUs-Hin" exact component={AboutUsHin} />
          <Route path="/index-kan" exact component={HomeKan} />
          <Route path="/s/*" component={() => <ShopLandingS />} />
          <Route path="/*" component={() => <ShopLanding />} />
          <Route exact path="/i/hello.html" />
        </Switch>
      </Router>
    </div>
  );
}
export default App;

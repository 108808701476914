import React, { useState } from "react";
import styled from "styled-components";
import gsf from "../assets/logos/gsf.png";
import startUpIndia from "../assets/logos/startup-india.png";
import startUpKarnataka from "../assets/logos/startup-karnataka.png";
import wadhwani from "../assets/logos/Wadhwani-Foundation.png";




const LogoSection = () => {

    const logos = [
        gsf,
        startUpIndia,
        startUpKarnataka,
       wadhwani
      ];
  
  return (
    <div>
    {/* <h2 style={{textAlign: "center", marginBottom: "60px"}}>Company Logos</h2> */}
    <div className="logo-container" style={{ display: "flex", justifyContent : "space-around", flexWrap: "wrap", maxWidth: "70%", /* Adjust as needed */ margin: "0 auto"}}>
      {logos.map((logo, index) => (
        <img style={{width: "150px",
        height: "100px", /* Set your desired height */
        objectFit: "contain", /* Maintain aspect ratio */
        margin: "10px"}} key={index} src={logo} alt={`Company Logo ${index + 1}`} />
      ))}
    </div>
  </div>
  );
};

export default LogoSection;
